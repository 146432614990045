import { Component, Input, Output, EventEmitter, NgZone, OnInit } from '@angular/core';
import { StringFilterHex } from 'src/app/app.utils';
import { BarcodeScanProvider } from 'src/app/providers/qr/barcode-scan';
import { CalibrateService } from 'src/app/providers/calibrate/calibrate.service';
import { HelperProvider } from 'src/app/providers/helper/helper';
import { LocaLstorageService } from 'src/app/store/localstorage.service';
import { IonicModule, Platform } from '@ionic/angular';
import { DmStoreService } from 'src/app/store/dm-store.service';
import { DeviceBindStoreService } from 'src/app/store/device-bind-store.service';
import { BindGroupStatuses } from 'src/app/store/bindGroupStatuses';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

@Component({
  selector: 'app-device-input',
  templateUrl: './device-input.component.html',
  styleUrls: ['./device-input.component.scss'],
  standalone: true,
  imports: [IonicModule, FormsModule, CommonModule],
  providers: [CalibrateService, BarcodeScanProvider, BarcodeScanner],
})
export class DeviceInputComponent implements OnInit {
  public native = false;
  public calibrated: boolean;
  public deviceId = '';
  private Err: string;

  get error(): string {
    return this.Err;
  }

  @Input()
  set error(val: string) {
    this.Err = val;
  }
  @Input() disabled: boolean;
  @Input() index: number;
  @Input() disabledCalibration: boolean;

  @Output() newDevice = new EventEmitter<string>();
  @Output() calibrationStatus = new EventEmitter<boolean>();

  constructor(
    private barcodeScanProvder: BarcodeScanProvider,
    private calibrateService: CalibrateService,
    private ngZone: NgZone,
    private helper: HelperProvider,
    private localStoreService: LocaLstorageService,
    private platform: Platform,
    private dmStoreService: DmStoreService,
    private deviceBindStore: DeviceBindStoreService,
    private bindGroupStatuses: BindGroupStatuses,
  ) { }


  async ngOnInit() {
    await this.platform.ready();
    this.native = this.platform.is('capacitor') && this.platform.is('android');
    if (this.deviceBindStore.device) {
      this.deviceId = this.deviceBindStore.device.device_id;
      this.newDevice.emit(this.deviceId);
    }

    if (this.dmStoreService.preselectedValues.deviceId) {
      this.deviceId = this.dmStoreService.preselectedValues.deviceId;
      this.newDevice.emit(this.deviceId);
    }
  }

  public onChange() {
    setTimeout(() => {
      this.deviceId = this.deviceId.replace(StringFilterHex, '').toUpperCase();
      this.newDevice.emit(this.deviceId);
      this.calibrated = false;
      this.calibrationStatus.emit(false);
    });
  }

  public async scan() {
    this.error = '';
    this.bindGroupStatuses.qrScanning = true;

    const result = await this.barcodeScanProvder.scan();


    if (result === 'canceled') {
      setTimeout(() => this.bindGroupStatuses.qrScanning = false, 500);
      return;
    }

    if (result) {
      setTimeout(() => this.bindGroupStatuses.qrScanning = false, 500);
      this.deviceId = result.replace(StringFilterHex, '').toUpperCase();
      this.newDevice.emit(this.deviceId);

      if (this.localStoreService.autoCalibration && !this.disabledCalibration) {
        this.calibrate();
      }
    }
  }

  public calibrate() {
    this.calibrateService.start(
      this.deviceId,
      res => this.ngZone.run(() => {
        this.calibrated = !!res;
        this.calibrationStatus.emit(this.calibrated);
      }),
      () => this.helper.alert('Calibrate service isn\'t working on your device'),
    );
  }

}
