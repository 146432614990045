import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Injectable } from '@angular/core';

interface UserPayload {
  email: string;
  first_name: string;
  role: string;
  last_name: string;
  user_group: string;
  user_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserProfile {
  constructor() { }

  // get authenticated state
  private getSession(): Promise<CognitoUserSession | null> {
    return Auth.currentSession().catch(() => null);
  }

  public async getUser(): Promise<UserPayload | null> {
    const session = await this.getSession();

    if (session) {
      return session.getIdToken().payload as UserPayload;
    }

    return null;
  }

  public async isAdmin(): Promise<boolean> {
    const user = await this.getUser();

    return user?.role === 'admin';
  }
}
