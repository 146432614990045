type Primitive = boolean | number | string;

interface FormattedQuery {
  [key: string]: Primitive;
}
interface RawQuery {
  [key: string]: Array<Primitive> | Primitive | null | undefined;
}

function isNil(value?: Array<Primitive> | Primitive | null) {
  return value === null || value === undefined;
}

function checkEmptyString(value?: Primitive | null) {
  if (isNil(value)) {
    return true;
  }

  return typeof value === 'string' && !(value.trim());
}

export function formatQuery(query: RawQuery): FormattedQuery {
  const keyVal = Object.entries(query).map(([key, value]) => {
    if (isNil(value)) {
      return [];
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return [];
      }

      const withoutNil = value.filter(val => !checkEmptyString(val));
      return [key, withoutNil.join(',')];
    }

    if (checkEmptyString(value)) {
      return [];
    }

    return [key, value];
  });

  return keyVal.reduce((result, item) => {
    const key = item[0] as string;
    if (key) {
      result[key] = item[1] as Primitive;
    }
    return result;
  }, {} as FormattedQuery);
}
