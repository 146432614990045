/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EncryptionKeys,
    GroupType,
    Label,
    SoftType,
} from './';

/**
 * @export
 * @interface PositionedDeviceData
 */
export interface PositionedDeviceData {
    /**
     * `Device Primary Key`. HEX string
     * @type {string}
     * @memberof PositionedDeviceData
     */
    readonly device_id: string;
    /**
     * @type {boolean}
     * @memberof PositionedDeviceData
     */
    activation_status: boolean;
    /**
     * @type {boolean}
     * @memberof PositionedDeviceData
     */
    damaged_status: boolean;
    /**
     * `Zone Primary Key`
     * @type {number}
     * @memberof PositionedDeviceData
     */
    readonly zone_id?: number;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    zone_name?: string;
    /**
     * `Level Primary Key`
     * @type {number}
     * @memberof PositionedDeviceData
     */
    readonly level_id?: number;
    /**
     * Hardware type of device
     * @type {string}
     * @memberof PositionedDeviceData
     */
    hardware_type?: string | null;
    /**
     * @type {GroupType}
     * @memberof PositionedDeviceData
     */
    group_type?: GroupType;
    /**
     * `Position Primary Key`
     * @type {number}
     * @memberof PositionedDeviceData
     */
    readonly position_id?: number;
    /**
     * ***Position group floor number***
     * @type {number}
     * @memberof PositionedDeviceData
     */
    floor_number?: number;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    level_name?: string;
    /**
     * @type {number}
     * @memberof PositionedDeviceData
     */
    lat?: number;
    /**
     * @type {number}
     * @memberof PositionedDeviceData
     */
    lon?: number;
    /**
     * `Group Primary Key`
     * @type {number}
     * @memberof PositionedDeviceData
     */
    readonly group_id?: number;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    group_name?: string;
    /**
     * @type {number}
     * @memberof PositionedDeviceData
     */
    group_inner_id?: number;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    group_custom_id?: string;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof PositionedDeviceData
     */
    custom_id?: string;
    /**
     * ***Position network_id***
     * @type {string}
     * @memberof PositionedDeviceData
     */
    network_id?: string;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    firmware_hash?: string;
    /**
     * @type {EncryptionKeys}
     * @memberof PositionedDeviceData
     */
    encryption_keys?: EncryptionKeys;
    /**
     * `Only Admin can use this field` *Readonly for another users*
     * @type {number}
     * @memberof PositionedDeviceData
     */
    owner_id?: number;
    /**
     * @type {string}
     * @memberof PositionedDeviceData
     */
    high_level_protocol_version?: string;
    /**
     * @type {SoftType}
     * @memberof PositionedDeviceData
     */
    soft_type?: SoftType;
    /**
     * `Project ID`
     * @type {number}
     * @memberof PositionedDeviceData
     */
    project_id?: number;
    /**
     * Device calibration status
     * @type {boolean}
     * @memberof PositionedDeviceData
     */
    calibration_status: boolean;
    /**
     * Device calibration datetime
     * @type {Date}
     * @memberof PositionedDeviceData
     */
    calibration_date_time?: Date | null;
    /**
     * @type {Array<Label>}
     * @memberof PositionedDeviceData
     */
    labels?: Array<Label>;
}
