/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Permissions schema
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * @type {string}
     * @memberof Permissions
     */
    name: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    dm_zone?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    dm_device?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    user_management?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    fmw_management?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    http_sender?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    rmq_sender?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    monitoring?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    raw_messages?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    devices?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    stations?: string;
    /**
     * @type {string}
     * @memberof Permissions
     */
    provisioning?: string;
    /**
     * @type {boolean}
     * @memberof Permissions
     */
    template?: boolean;
    /**
     * @type {number}
     * @memberof Permissions
     */
    id: number;
}
