export enum EnumPositionsFilter {
  limit = 'limit',
  offset = 'offset',
  positions = 'position_id',
  level = 'level_id',
  levelNumber = 'level',
  owner = 'owner_id',
  groupIds = 'group_id',
  groupInnerIds = 'group_inner_id',
  projects = 'project_id',
  zones = 'zone_id',
  bindingStatus = 'bound',
  networkIds = 'network_id',
  customIds = 'custom_id',
  lat = 'lat',
  lon = 'lon',
  radius = 'radius',
}

export interface PositionsFilters {
  [EnumPositionsFilter.limit]?: number;
  [EnumPositionsFilter.offset]?: number;
  [EnumPositionsFilter.positions]?: number[];
  [EnumPositionsFilter.level]?: number[];
  [EnumPositionsFilter.levelNumber]?: number;
  [EnumPositionsFilter.owner]?: number;
  [EnumPositionsFilter.groupIds]?: number[];
  [EnumPositionsFilter.groupInnerIds]?: string[];
  [EnumPositionsFilter.projects]?: number[];
  [EnumPositionsFilter.zones]?: number[];
  [EnumPositionsFilter.bindingStatus]?: string;
  [EnumPositionsFilter.networkIds]?: string[];
  [EnumPositionsFilter.customIds]?: string[];
  [EnumPositionsFilter.lat]?: number;
  [EnumPositionsFilter.lon]?: number;
  [EnumPositionsFilter.radius]?: number;
}

export enum EnumDevicesFilter {
  limit = 'limit',
  offset = 'offset',
  devices = 'device_id',
  isActive = 'active',
  hardwareType = 'hardware_type',
  isDamaged = 'damaged',
  zones = 'zone_id',
  groups = 'group_id',
  owner = 'owner',
  isPositioned = 'positioned_status',
  projects = 'project_id',
  positions = 'positions',
  groupInnerIds = 'group_inner_id',
  level = 'level_id',
  firmwareId = 'firmware_hash',
  networkIds = 'network_id'
}

export interface DevicesFilters {
  [EnumDevicesFilter.limit]?: number;
  [EnumDevicesFilter.offset]?: number;
  [EnumDevicesFilter.devices]?: string[];
  [EnumDevicesFilter.isActive]?: string;
  [EnumDevicesFilter.hardwareType]?: string;
  [EnumDevicesFilter.isDamaged]?: string;
  [EnumDevicesFilter.zones]?: number[];
  [EnumDevicesFilter.groups]?: number[];
  [EnumDevicesFilter.owner]?: number;
  [EnumDevicesFilter.isPositioned]?: string;
  [EnumDevicesFilter.projects]?: number[];
  [EnumDevicesFilter.positions]?: number[];
  [EnumDevicesFilter.groupInnerIds]?: string[];
  [EnumDevicesFilter.level]?: number[];
  [EnumDevicesFilter.firmwareId]?: number;
  [EnumDevicesFilter.networkIds]?: string[];
  force?: boolean;
}

export enum EnumGroupsFilter {
  limit = 'limit',
  offset = 'offset',
  zones = 'zone_id',
  levels = 'level_id',
}

export interface GroupsFilters {
  [EnumGroupsFilter.limit]?: number;
  [EnumGroupsFilter.offset]?: number;
  [EnumGroupsFilter.zones]?: number[];
  [EnumGroupsFilter.levels]?: number[];
}

export enum EnumLevelsFilters {
  limit = 'limit',
  offset = 'offset',
  zones = 'zone_id',
  floor_number = 'floor_number',
}

export interface LevelsFilters {
  [EnumLevelsFilters.limit]?: number;
  [EnumLevelsFilters.offset]?: number;
  [EnumLevelsFilters.zones]?: number[];
  [EnumLevelsFilters.floor_number]?: number[];
}

export enum EnumProjectsFilters {
  limit = 'limit',
  offset = 'offset',
  owner = 'owner_id',
}

export interface ProjectsFilters {
  [EnumProjectsFilters.limit]?: number;
  [EnumProjectsFilters.offset]?: number;
  [EnumProjectsFilters.owner]?: number;
}

export enum EnumZonesFilters {
  limit = 'limit',
  offset = 'offset',
  project = 'project_id',
}

export interface ZonesFilters {
  [EnumZonesFilters.limit]?: number;
  [EnumZonesFilters.offset]?: number;
  [EnumZonesFilters.project]?: number;
}
