import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { Geolocation } from '@capacitor/geolocation';
import { environment } from 'src/environments/environment';
import { LatLngBounds } from '@capacitor/google-maps';
import { Loader } from '@googlemaps/js-api-loader';

import { PositionedDeviceData, Position } from 'src/app/model';
import { NativeMapsService } from './native-maps.service';
import { MapsUtils } from './maps-helper.service';
import { CustomMarkers, LatLon, CustomMarker } from 'src/app/model/maps';
import { LocaLstorageService } from 'src/app/store/localstorage.service';
import { DmStoreService } from 'src/app/store/dm-store.service';
import { DeviceBindStoreService } from 'src/app/store/device-bind-store.service';
import { GroupBindStoreService } from 'src/app/store/group-bind-store.service';
import { Platform } from '@ionic/angular';
import { LatLng } from 'src/app/types/googlemap.capacitor.types';

@Injectable({
  providedIn: 'root',
})
export class MapsService {
  private map: NativeMapsService;
  public subscription: Subscription;
  public changes = false;
  private readonly visible = 30;
  private devices: PositionedDeviceData[] = [];
  private devicesCount = 0;
  private positions: Position[] = [];
  private positionsCount = 0;
  private markersIsBusy = true;

  private showMarkers = debounce(this.updateMarkers, 500);

  constructor(
    public utils: MapsUtils,
    private dmStore: DmStoreService,
    private locaSlorageService: LocaLstorageService,
    private deviceBind: DeviceBindStoreService,
    private groupBind: GroupBindStoreService,
    private platform: Platform,
  ) {}

  public async init(location: LatLng, element: HTMLElement) {
    this.map = new NativeMapsService(this.dmStore);
    const native = this.platform.is('capacitor') && this.platform.is('android');
    const apiKey = native ? environment.GoogleMapApiKey : environment.GoogleMapApiKeyWeb;

    await this.map.init(location, element, apiKey);

    this.deviceBind.accurateDevicePosition = false;
    this.groupBind.accuratePositions = false;

    this.locaSlorageService.mapType$.subscribe((mapType) => this.map.setType(mapType));

    this.subscription = this.dmStore.markers$.subscribe(([devices, positions]) => {
      this.devicesCount = devices.length;
      this.positionsCount = positions.length;
      this.devices = devices;
      this.positions = positions;

      if (!this.devicesCount) {
        this.map.clearDevicesMarkers();
      }
      if (!this.positionsCount) {
        this.map.clearPositionsMarkers();
      }

      if (!this.devicesCount && !this.positionsCount) {
        return false;
      }

      this.showMarkers();

      return true;
    });

    this.map.dragEnd(() => !this.markersIsBusy && this.showMarkers());

    this.markersIsBusy = false;

    const loader = new Loader({
      apiKey: environment.GoogleMapApiKeyWeb,
      version: 'weekly',
      libraries: ['places', 'geometry'],
    });

    // load google maps to window anyway. This is required in native mode
    if (!window.google) {
      await loader.importLibrary('maps');
    }

    const status = await Geolocation.checkPermissions();

    if (!status.location) {
      await Geolocation.requestPermissions();
    }
  }

  public setOptionsForAccurate(status = false) {
    this.map.setOptionsForAccurate(status);
  }

  public async getCurrentPosition(): Promise<void | LatLon> {
    try {
      const position = await Geolocation.getCurrentPosition();
  
      if (position) {
        return { lat: position.coords.latitude, lon: position.coords.longitude };
      }
    } catch (error) {
      Sentry.captureException('Geolocation error');
    }
  }

  public drawMarkers(markers: CustomMarkers) {
    this.map.drawCustomMarkers(markers);
  }

  public clearCustomMarkers() {
    this.map.clearCustomMarkers();
  }

  public moveMarkers(markers: CustomMarkers) {
    this.map.moveMarkers(markers);
  }

  private async updateMarkers() {
    const visibleRegion: LatLngBounds = await this.map.getVisible();
    const devices = this.reduceMarkersList<PositionedDeviceData>(this.devices, this.devicesCount, visibleRegion);
    const positions = this.reduceMarkersList<Position>(this.positions, this.positionsCount, visibleRegion);

    this.markersIsBusy = true;

    if (this.map.getZoom() >= 20) {
      await this.map.deleteUnnecessaryMarkers(devices, positions);
    }

    await this.map.addDevices(devices);
    await this.map.addPositions(positions);

    const markers = [...devices, ...positions];

    if (this.changes && markers.length) {
      this.changes = false;
      this.map.bound(markers.map(({ lat, lon }) => ({ lat: lat || 0, lon: lon || 0 })));
    }
    this.markersIsBusy = false;
  }

  // reduce markers by zoom and visible option
  private reduceMarkersList<T extends PositionedDeviceData | Position>(markers: T[], total: number, visibleRegion: LatLngBounds): T[] {
    const delimeter = Math.floor(total / this.visible);

    if (delimeter <= 1) {
      return markers;
    }

    return this.showMarkersInVisibleRegion<T>(markers, visibleRegion);
  }

  private thinOutMarkers<T extends PositionedDeviceData | Position>(markers: T[], total: number): T[] {
    const delimeter = Math.floor(total / this.visible);

    if (delimeter <= 1) {
      return markers;
    }

    const visible: T[] = [];

    for (let index = 0; index <= total; index++) {
      if ((index % delimeter) === 0 && markers[index]) {
        visible.push(markers[index]);
      }
    }

    return visible;
  }

  private showMarkersInVisibleRegion<T extends PositionedDeviceData | Position>(devices: T[], visibleRegion: LatLngBounds): T[] {
    const visible: T[] = [];

    for (const device of devices) {
      const contains =
        visibleRegion.northeast.lat >= (device.lat || 0) &&
        visibleRegion.southwest.lat <=  (device.lat || 0) &&
        visibleRegion.northeast.lng >= (device.lon || 0) &&
        visibleRegion.southwest.lng <= (device.lon || 0);

      if (contains) {
        visible.push(device);
      }
    }

    return this.thinOutMarkers(visible, visible.length);
  }

  public bound(latLon: LatLon[]) {
    this.map.bound(latLon);
  }

  public setCenter(lat: number, lon: number, zoom?: number) {
    this.map.setCenter(lat, lon, zoom);
  }

  public getCenter() {
    return this.map.getCenter();
  }

  public async drawDotInMapCenter() {
    await this.map.drawDotInMapCenter();
  }

  public drawPolyline(path?: google.maps.LatLng[]) {
    if (path) {
      const convertedPath: LatLng[] = path.map((p) => {
        const nativePath: LatLng = {
          lat: p.lat(),
          lng: p.lng(),
        };
        return nativePath;
      });
      this.map.drawPolyline(convertedPath);
    }
  }

  public removePolyline() {
    this.map.deletePolyline();
  }

  public clearMap() {
    this.map.clearMap();
  }

  public getMarkerOptions(key: string, latLon: LatLon, color: string): CustomMarker {
    return {
      key,
      latLon,
      options: {
        color,
      },
    };
  }
}
