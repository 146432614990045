import { Injectable, NgZone } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Auth, Hub } from 'aws-amplify';
import { NavController } from '@ionic/angular';
import { DecodedJwt } from 'src/app/model';
import { AccessStoreService } from 'src/app/store/access-store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private navController: NavController,
    private state: AccessStoreService,
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);

    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        this.loggedIn.next(true);
        this.ngZone.run(() => {
          this.router.navigate(['/home'], { replaceUrl: true });
        });
      } else {
        this.router.navigate(['/auth'], { replaceUrl: true });
        this.loggedIn.next(false);
      }
    });
  }

  // get authenticated state
  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentSession())
      .pipe(
        map(authState => {
          this.state.setWriteAccess(authState.getIdToken().payload as DecodedJwt);
          this.loggedIn.next(true);
          return true;
        }),
        catchError(() => {
          this.loggedIn.next(false);
          return of(false);
        }),
      );
  }

  public signOut(): void {
    Auth.signOut().then(() => {
      this.navController.setDirection('root');
      this.loggedIn.next(false);
    }).catch(err => Sentry.captureException(err));
  }
}
