import { Routes } from '@angular/router';
import { AuthGuard } from './providers';

export const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.routes').then(m => m.routes),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then(m => m.routes),
  },
];
