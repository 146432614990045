/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DeviceDamagesLog
 */
export interface DeviceDamagesLog {
    /**
     * @type {string}
     * @memberof DeviceDamagesLog
     */
    id: string;
    /**
     * @type {boolean}
     * @memberof DeviceDamagesLog
     */
    damaged: boolean;
    /**
     * @type {Date}
     * @memberof DeviceDamagesLog
     */
    datetime: Date;
    /**
     * `Device Primary Key`. HEX string
     * @type {string}
     * @memberof DeviceDamagesLog
     */
    readonly device_id: string;
}
