import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DecodedJwt } from 'src/app/model';
import * as Sentry from '@sentry/capacitor';

@Injectable({
  providedIn: 'root',
})
export class AccessStoreService {
  constructor() { }

  private readonly WRITEACCESS = new BehaviorSubject<boolean>(false);
  readonly writeaccess$: Observable<boolean> = this.WRITEACCESS.asObservable();

  private readonly CREATEPROJECTACCESS = new BehaviorSubject<boolean>(false);
  readonly createprojectaccess$: Observable<boolean> = this.CREATEPROJECTACCESS.asObservable();

  public setWriteAccess(payload: DecodedJwt) {
    const dmAccess = !!(payload.role === 'admin' || payload['dm.device'] && payload['dm.device'].includes('u'));
    const projectAccess = !!(payload.role === 'admin' || payload['um.user'] && payload['um.user'].includes('c'));
    this.WRITEACCESS.next(dmAccess);
    this.CREATEPROJECTACCESS.next(projectAccess);

    Sentry.configureScope(scope => scope.setUser({ email: payload['email'], role: payload.role }));
  }
}
