import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, ModalController, Platform, ToastController } from '@ionic/angular';
import * as Sentry from '@sentry/capacitor';
import { CommonModule } from '@angular/common';
import { SplashScreen } from '@capacitor/splash-screen';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { DmStoreService } from './store/dm-store.service';
import { ApiService, HelperProvider } from './providers';
import { register } from 'swiper/element/bundle';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LocaLstorageService } from './store/localstorage.service';
import { BackButtonHandlerProvider } from './providers/backButtonProvider';
import { GroupBindStoreService } from './store/group-bind-store.service';
import { CustomMarker, CustomMarkers, LatLon } from './model/maps';
import { DeviceBindStoreService } from './store/device-bind-store.service';
import { MapsService } from './providers/maps/maps.service';
import { BindDevicesListModalResult, PositionUpdateFields } from './model';
import { backGroupModalClass, sendSuccessTodnwaveio } from './app.utils';
import { BindGroupComponent } from './modals/bind-group/bind-group.modal';
import { ConnectionStatusComponent } from './components/connections-status/connections-status.component';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, AmplifyAuthenticatorModule, IonicStorageModule, ConnectionStatusComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HelperProvider, BackButtonHandlerProvider, ToastController],
})
export class AppComponent implements AfterViewInit {
  public environmentInjector = inject(EnvironmentInjector);

  private poly?: google.maps.Polyline;
  private points: LatLon[];
  private savedCustomMarkers: { [key: string]: CustomMarker } = {};

  public accurateGroupPositions$ = this.groupBindStore.accuratePositions$;
  public accurateDevicePosition$ = this.deviceBindStore.accurateDevicePosition$;
  public existingPositionForBind$ = this.deviceBindStore.existingPositionForBind$;
  public points$ = this.groupBindStore.pointsPositions$;
  public loadingBindGroup$ = this.dmStoreService.loadingBindGroup$;

  constructor(
    private modalController: ModalController,
    private dmStoreService: DmStoreService,
    private localStorageService: LocaLstorageService,
    private toastController: ToastController,
    private backButtonHundler: BackButtonHandlerProvider,
    private groupBindStore: GroupBindStoreService,
    private deviceBindStore: DeviceBindStoreService,
    private maps: MapsService,
    private platform: Platform,
    private helper: HelperProvider,
    private api: ApiService,
  ) {}

  ngAfterViewInit() {
    SplashScreen.hide();
    this.localStorageService.init();

    // Implementation for redirect from d.mwave.io
    const params = new URLSearchParams(window.location.search);
    if (params) {
      this.dmStoreService.preselectedValues = {
        zoneId: Number(params.get('zoneId')) || undefined,
        groupId: Number(params.get('groupId')) || undefined,
        positionId: Number(params.get('positionId')) || undefined,
        levelId: Number(params.get('levelId')) || undefined,
        deviceId: params.get('deviceId') || undefined,
      };
    }

    this.platform.backButton.subscribe(() => this.backButtonHundler.backButton(this.restartFromStartPoint.bind(this)));

    this.groupBindStore.positions$.subscribe(positions => positions.length ? this.openBindGroupList() : null);
    this.groupBindStore.accuratePositions$.subscribe(async status => status !== null && await this.accurateGroup(status));
    this.deviceBindStore.accurateDevicePosition$.subscribe(async status => status !== null && await this.accurateDevice(status));
  }

  /**
   * ###############################
   * ACCURATE POSITIONING METHODS START
   * ###############################
   */
  public cancelAccuratePositions = () => this.backButtonHundler.backAccuratePositions(this.restartFromStartPoint.bind(this));
  public cancelAccuratePosition = () => this.backButtonHundler.cancelAccuratePosition();

  public restartAccurate = () => this.groupBindStore.accuratePositions = true;

  public async move(angle: 90 | 180 | 270 | 360) {
    if (this.poly) {
      const customMarkers = Object.values(this.savedCustomMarkers);
      const moved = this.maps.utils.moveCustomMarker(customMarkers, angle);
      this.maps.moveMarkers(moved);
      this.poly = this.maps.utils.movePolyline(angle);
      this.maps.removePolyline();
      this.maps.drawPolyline(this.poly?.getPath().getArray());
    } else {
      // google.maps.geometry init hack
      await this.maps.drawDotInMapCenter();
      const mapCenter = this.maps.getCenter();
      try {
        const moved = this.maps.utils.moveMarker([{ lat: mapCenter.lat, lon: mapCenter.lng }], angle);
        this.maps.setCenter(moved[0].lat, moved[0].lon);
      } catch (error) {
        // do nothing
      }
    }
  }

  private restartFromStartPoint() {
    if (this.poly) {
      this.maps.removePolyline();
      this.poly = undefined;
    }
    this.maps.clearCustomMarkers();
    this.maps.setCenter(this.points[0].lat, this.points[0].lon);
    const point: CustomMarkers = [this.maps.getMarkerOptions('start', this.points[0], 'green')];
    delete this.savedCustomMarkers['finish'];
    this.points = new Array(this.groupBindStore.devicesNumber);
    if (this.groupBindStore.pointsPositions.start) {
      this.points[0] = this.groupBindStore.pointsPositions.start;
    }

    this.maps.drawMarkers(point);
  }

  private async accurateDevice(status: boolean) {
    if (status) {
      this.maps.setOptionsForAccurate(true);
      if (this.deviceBindStore.positionsInGroup.length) {
        const latlon: LatLon[] = this.deviceBindStore.positionsInGroup.map(position => ({
          lat: position.lat,
          lon: position.lon,
        }));

        this.maps.bound(latlon);
      }
    } else {
      this.deviceBindStore.existingPositionForBind = undefined;
      this.maps.setOptionsForAccurate();
      this.maps.removePolyline();
      this.maps.drawPolyline(this.poly?.getPath().getArray());
      this.poly = undefined;
    }
  }

  private async accurateGroup(status: boolean) {
    if (this.poly) {
      this.maps.removePolyline();
      this.poly = undefined;
    }

    this.maps.clearCustomMarkers();
    this.groupBindStore.pointsPositions = { start: undefined, finish: undefined };
    this.points = new Array(this.groupBindStore.devicesNumber);

    if (status) {
      this.maps.setOptionsForAccurate(true);
      if (this.groupBindStore.existingPositions.length) {
        const latlon: LatLon[] = this.groupBindStore.existingPositions.map(position => ({
          lat: position.lat,
          lon: position.lon,
        }));

        this.maps.bound(latlon);
      }
    } else {
      this.maps.setOptionsForAccurate();
    }
  }

  public accurateDevicePosition() {
    const mapCenter = this.maps.getCenter();
    this.createPosition({ lat: mapCenter.lat, lon: mapCenter.lng });
  }

  public async accurateGroupPosition() {
    const mapCenter = this.maps.getCenter();

    const { start, finish } = this.groupBindStore.pointsPositions;

    if (
      (start?.lat || 0 * 100000).toFixed(5) === (mapCenter?.lat || 0 * 100000).toFixed(5) &&
      (start?.lon || 0 * 100000).toFixed(5) === (mapCenter?.lng || 0 * 100000).toFixed(5)
    ) {
      this.helper.alert('Start and finish positions are the same');
      return;
    }

    if (!start) {
      this.groupBindStore.pointsPositions = {
        start: await this.maps.utils.setToRoad({ lat: mapCenter.lat, lon: mapCenter.lng }),
        finish: undefined,
      };
      if (this.groupBindStore.pointsPositions.start) {
        this.points[0] = this.groupBindStore.pointsPositions.start;
      }

      const point: CustomMarkers = [this.maps.getMarkerOptions('start', this.points[0], 'green')];
      this.savedCustomMarkers['start'] = point[0];

      this.maps.drawMarkers(point);

      this.maps.setCenter(this.points[0].lat, this.points[0].lon);
    } else if (start && !finish) {
      const lastIndex = this.points.length - 1;

      this.groupBindStore.pointsPositions = {
        start,
        finish: await this.maps.utils.setToRoad({ lat: mapCenter.lat, lon: mapCenter.lng }),
      };
      if (this.groupBindStore.pointsPositions.finish) {
        this.points[lastIndex] = this.groupBindStore.pointsPositions.finish;
      }

      const point: CustomMarkers = [this.maps.getMarkerOptions('finish', this.points[lastIndex], 'green')];
      this.savedCustomMarkers['finish'] = point[0];

      this.maps.drawMarkers(point);
      this.showMilestones();
    } else {
      this.openBindGroup();
    }
  }

  private openBindGroup() {
    const newPositions: PositionUpdateFields[] = [];
    let innerId = 0;

    for (const point of this.points) {
      if (!point) {
        Sentry.addBreadcrumb({ data: point });
        Sentry.captureException('Empty point');
        return;
      }
      newPositions.push({
        lat: point.lat,
        lon: point.lon,
        group_id: this.groupBindStore.group?.id || 0,
        group_inner_id: ++innerId,
        custom_id: '',
      });
    }

    this.groupBindStore.positions = newPositions;
  }

  private async showMilestones() {
    const { start, finish } = this.groupBindStore.pointsPositions;

    if (!start || !finish) {
      return;
    }

    const milestones = await this.maps.utils.getMilestones(
      { lat: start.lat, lng: start.lon },
      { lat: finish.lat, lng: finish.lon },
      this.groupBindStore.devicesNumber,
      this.localStorageService.intervalMeters,
    ).catch((error) => {
      this.helper.alert('Failed to get milestones from start point to finish point');
      Sentry.captureException(`${error}`);
    });

    if (!milestones) {
      return;
    }

    const { result, polyline } = milestones;

    for (const [index, point] of result.entries()) {
      this.points[index + 1] = point;
    }

    this.poly = polyline;
    this.maps.drawPolyline(polyline?.getPath().getArray());

    if ((result.length || this.groupBindStore.devicesNumber === 2) && !this.localStorageService.intervalPlacing) {
      const markers: CustomMarkers = result.map((res, i) => this.createMilestoneMarker(res, i));

      this.maps.drawMarkers(markers);

      this.maps.bound([
        this.groupBindStore.pointsPositions.start as LatLon,
        this.groupBindStore.pointsPositions.finish as LatLon,
        ...markers.map(m => m.latLon),
      ]);
    } else if (result.length && this.localStorageService.intervalPlacing) {
      const finishedPoint = this.maps.getMarkerOptions('finish', result[result.length - 1], 'green');
      this.savedCustomMarkers['finish'] = finishedPoint;

      const markers: CustomMarkers = result
        .slice(0, result.length - 1)
        .map((res, i) => this.createMilestoneMarker(res, i))
        .concat(finishedPoint);

      this.maps.drawMarkers(markers);

      this.maps.bound([
        this.groupBindStore.pointsPositions.start as LatLon,
        ...markers.map(m => m.latLon),
      ]);
    } else {
      this.helper.alert('Failed to draw sensors');
    }
  }

  private createMilestoneMarker(latLon: LatLon, i: number): CustomMarker {
    const marker = this.maps.getMarkerOptions(`id${i}`, latLon, 'yellow');
    this.savedCustomMarkers[`id${i}`] = marker;

    return marker;
  }

  private async createPosition(latLon: LatLon) {
    this.helper.showLoading();

    const positions = this.deviceBindStore.positionsInGroup;
    const maxId = positions.length ? Math.max(...positions.map(p => p.group_inner_id)) : 0;

    const position: PositionUpdateFields = {
      custom_id: this.deviceBindStore.newCustomId || null,
      group_id: this.deviceBindStore.group as number,
      group_inner_id: maxId + 1,
      lat: latLon.lat % 90,
      lon: latLon.lon % 180,
    };

    const createdPosition = await this.api.positions.create(position, this.helper);

    if (createdPosition && this.deviceBindStore.device) {
      const bind = await this.api.devices.bindDeviceToPosition(
        this.deviceBindStore.device.device_id,
        { position_id: createdPosition },
      );

      if (!bind.error) {
        await this.attachLabels(createdPosition);
        this.helper.showToast('Device has been bound successfully');
        sendSuccessTodnwaveio({ device: this.deviceBindStore.device.device_id });
        this.maps.bound([latLon]);
        this.dmStoreService.bindDevice(this.deviceBindStore.device.device_id, createdPosition);
        this.deviceBindStore.accurateDevicePosition = false;
        this.deviceBindStore.device = null;
      } else {
        this.helper.alert(bind.error || 'Can\'t bind device to position');
        this.deviceBindStore.accurateDevicePosition = false;
      }
    }

    if (createdPosition && this.deviceBindStore.deviceSkipped) {
      await this.attachLabels(createdPosition);
      this.deviceBindStore.accurateDevicePosition = false;
      this.dmStoreService.updatePosition(createdPosition);
    }

    this.helper.hideLoading();
  }

  private async attachLabels(positionId: number): Promise<boolean | void> {
    const labelsIds = this.dmStoreService.selectedLabels.value.map(l => l.id);

    if (!labelsIds.length) {
      return Promise.resolve(true);
    }

    this.helper.showLoading('Attaching labels...');
    const attached = await this.api.positions.attachLabels({
      positions: [positionId],
      labels: labelsIds,
    }, this.helper);

    this.helper.hideLoading();

    return attached;
  }


  private async openBindGroupList() {
    const modal = await this.modalController.create({
      component: BindGroupComponent,
      backdropDismiss: false,
      cssClass: backGroupModalClass,
    });

    modal.present();

    const { data } = await modal.onDidDismiss<BindDevicesListModalResult>();

    if (data?.done) {
      this.groupBindStore.accuratePositions = false;

      const toast = await this.toastController.create({
        message: 'Positions created',
        duration: 1000,
      });
      toast.present();
      sendSuccessTodnwaveio({ devices: data.done.devices.join(',') });

      this.dmStoreService.groupBindDevices(data.done.devices, data.done.positions);
    }

    if (data?.cancel) {
      this.groupBindStore.accuratePositions = false;
    }
  }

  public unpinDeviceFromPosition() {
    this.deviceBindStore.existingPositionForBind = undefined;
    this.move(90);
  }
  /**
   * ###############################
   * ACCURATE POSITIONING METHODS END
   * ###############################
   */
}
