/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EncryptionKeys,
    SoftType,
} from './';

/**
 * @export
 * @interface VirtualDevice
 */
export interface VirtualDevice {
    /**
     * Hardware type of device
     * @type {string}
     * @memberof VirtualDevice
     */
    hardware_type?: string | null;
    /**
     * @type {string}
     * @memberof VirtualDevice
     */
    firmware_hash?: string;
    /**
     * @type {EncryptionKeys}
     * @memberof VirtualDevice
     */
    encryption_keys?: EncryptionKeys;
    /**
     * @type {boolean}
     * @memberof VirtualDevice
     */
    activation_status?: boolean;
    /**
     * @type {boolean}
     * @memberof VirtualDevice
     */
    damaged_status?: boolean;
    /**
     * `Only Admin can use this field` *Readonly for another users*
     * @type {number}
     * @memberof VirtualDevice
     */
    owner_id?: number;
    /**
     * @type {string}
     * @memberof VirtualDevice
     */
    high_level_protocol_version?: string;
    /**
     * @type {SoftType}
     * @memberof VirtualDevice
     */
    soft_type?: SoftType;
    /**
     * Device calibration status
     * @type {boolean}
     * @memberof VirtualDevice
     */
    calibration_status?: boolean;
    /**
     * Device calibration datetime
     * @type {Date}
     * @memberof VirtualDevice
     */
    calibration_date_time?: Date | null;
}
