<ion-app>
  <ion-header *ngIf="accurateGroupPositions$ | async">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="cancelAccuratePositions()"><ion-icon name="arrow-back-outline"></ion-icon></ion-button>
      </ion-buttons>
      <ion-title>Accurate positioning</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="accurateGroupPosition()" color="primary" fill="solid" strong>
          <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
          ok
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-header *ngIf="accurateDevicePosition$ | async">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="cancelAccuratePosition()"><ion-icon name="arrow-back-outline"></ion-icon></ion-button>
      </ion-buttons>
      <ion-title>Accurate positioning</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="accurateDevicePosition()" color="primary" fill="solid" strong>
          <ion-icon slot="start" name="checkmark-circle-outline"></ion-icon>
          ok
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-router-outlet></ion-router-outlet>

  <div class="backdrop" *ngIf="((loadingBindGroup$ | async)?.[0] || 0) > 0">
    <div class="backdrop-spinner">
      <ion-spinner color="primary"></ion-spinner> Positions created {{(loadingBindGroup$ | async)?.[0]}}/{{(loadingBindGroup$ | async)?.[1]}}
    </div>
  </div>

  <ion-fab vertical="top" horizontal="center" slot="fixed" *ngIf="(accurateGroupPositions$ | async) || (accurateDevicePosition$ | async)" class="margin-top-fix">
    <ion-fab-button (click)="move(360)"><ion-icon name="caret-up-outline"></ion-icon></ion-fab-button>
  </ion-fab>
  <ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="(accurateGroupPositions$ | async) || (accurateDevicePosition$ | async)">
    <ion-fab-button (click)="move(90)"><ion-icon name="caret-forward-outline"></ion-icon></ion-fab-button>
  </ion-fab>
  <ion-fab vertical="bottom" horizontal="center" slot="fixed" *ngIf="(accurateGroupPositions$ | async) || (accurateDevicePosition$ | async)" class="margin-bottom-fix">
    <ion-fab-button (click)="move(180)"><ion-icon name="caret-down-outline"></ion-icon></ion-fab-button>
  </ion-fab>
  <ion-fab vertical="center" horizontal="left" slot="fixed" *ngIf="(accurateGroupPositions$ | async) || (accurateDevicePosition$ | async)">
    <ion-fab-button (click)="move(270)"><ion-icon name="caret-back-outline"></ion-icon></ion-fab-button>
  </ion-fab>


  <ion-footer *ngIf="accurateGroupPositions$ | async">
    <ion-grid>
      <ion-row align-items-start class="ion-justify-content-center ion-align-items-center">
        <ion-col class="ion-no-padding ion-no-margin">
          <ion-button (click)="restartAccurate()" fill="clear" [color]="(points$ | async)?.start ? 'success' : 'danger'">
            <ion-icon slot="start" name="checkmark-outline" *ngIf="(points$ | async)?.start"></ion-icon>
            <ion-icon slot="start" name="close-outline" *ngIf="(points$ | async)?.start === undefined"></ion-icon>
            Start point
          </ion-button>
        </ion-col>
        <ion-col align-self-end size="auto">
          <ion-button fill="clear" [color]="(points$ | async)?.finish ? 'success' : 'danger'">
            <ion-icon slot="start" name="checkmark-outline" *ngIf="(points$ | async)?.finish"></ion-icon>
            <ion-icon slot="start" name="close-outline" *ngIf="(points$ | async)?.finish === undefined"></ion-icon>
            Finish point
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>

  <ion-footer *ngIf="accurateDevicePosition$ | async">
    <ion-grid>
      <ion-row align-items-start class="ion-justify-content-center ion-align-items-center">
        <ion-col class="ion-no-padding ion-no-margin ion-text-center">
          <ion-button fill="clear" color="success" *ngIf="(existingPositionForBind$ | async) === undefined">
            <ion-icon slot="start" name="checkmark-outline"></ion-icon>
            Create new position
          </ion-button>
          <ion-button fill="clear" (click)="unpinDeviceFromPosition()" color="success" *ngIf="(existingPositionForBind$ | async) !== undefined">
            Use existing position
            <ion-icon slot="end" name="close-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>

  <app-connections-status></app-connections-status>
</ion-app>
