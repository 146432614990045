import { Injectable } from '@angular/core';

import { ApiServicePositions } from './api.service-positions';
import { ApiServiceZones } from './api.service-zones';
import { ApiServiceGroups } from './api.service-groups';
import { ApiServiceDevices } from './api.service-devices';
import { ApiServiceLevels } from './api.service-levels';
import { ApiServiceUsers } from './api.service-users';
import { ApiServiceLabels } from './api.service-labels';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public positions: ApiServicePositions,
    public zones: ApiServiceZones,
    public groups: ApiServiceGroups,
    public devices: ApiServiceDevices,
    public levels: ApiServiceLevels,
    public labels: ApiServiceLabels,
    public users: ApiServiceUsers,
  ) {}
}
