/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface LabelCount
 */
export interface LabelCount {
    /**
     * @type {string}
     * @memberof LabelCount
     */
    label: string;
    /**
     * @type {number}
     * @memberof LabelCount
     */
    id?: number;
    /**
     * @type {number}
     * @memberof LabelCount
     */
    count: number;
}
