import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, IonContent, IonicModule, Platform } from '@ionic/angular';
import { HelperProvider, ApiService } from 'src/app/providers';
import { PositionUpdateFields, BindDevicesListModalResult } from 'src/app/model';
import { map } from 'rxjs/operators';
import { backButtonMessage, generateCustomId } from 'src/app/app.utils';
import { DeviceInputComponent } from 'src/app/components/device-input/device-input.component';
import { GroupBindStoreService } from 'src/app/store/group-bind-store.service';
import { LocaLstorageService } from 'src/app/store/localstorage.service';
import { DmStoreService } from 'src/app/store/dm-store.service';
import { BindGroupStatuses } from 'src/app/store/bindGroupStatuses';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bind-group',
  templateUrl: './bind-group.modal.html',
  styleUrls: ['./bind-group.modal.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, DeviceInputComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BindGroupComponent implements OnInit {
  private native: boolean;

  public positions: {
    data: PositionUpdateFields,
    device?: string;
    skipped?: boolean;
    error?: string;
    done?: boolean;
    created?: boolean;
    id?: number;
    calibrated?: boolean;
  }[] = [];

  @ViewChild(IonContent, { static: false }) content: IonContent;

  constructor(
    private modalController: ModalController,
    private groupBindStore: GroupBindStoreService,
    private helper: HelperProvider,
    private api: ApiService,
    private localStorageService: LocaLstorageService,
    private platform: Platform,
    private dmStore: DmStoreService,
    private bindGroupStatuses: BindGroupStatuses,
  ) { }

  public customIdAutogen$ = this.localStorageService.customIdGeneration$.pipe(map(autogen => autogen));

  async ngOnInit() {
    this.native = this.platform.is('android') && this.platform.is('capacitor');

    if (!this.groupBindStore.positions || this.groupBindStore.positions.length < 2 || !this.groupBindStore.group?.id) {
      await this.helper.alert('Error occured. Please try again group bind');
      this.modalController.dismiss();
      return;
    }

    this.countInnerIdForPositions();
  }

  private async countInnerIdForPositions() {
    const { existingPositions } = this.groupBindStore;
    let maxId = existingPositions.length ? Math.max(...existingPositions.map(p => p.group_inner_id)) : 0;

    this.positions = this.groupBindStore.positions.map(position => ({
      data: {
        ...position,
        lat: position.lat % 90,
        lon: position.lon % 180,
        group_inner_id: ++maxId,
        custom_id: this.localStorageService.customIdGeneration ? generateCustomId() : null,
      },
      device: undefined,
      skipped: false,
      error: undefined,
      done: false,
      calibrated: false,
    }));
  }

  public disabledBind() {
    return this.positions.find(position => position.error || (!position.skipped && !position.device));
  }

  public attachDeviceToPosition(index: number, deviceId?: string) {
    this.positions[index].device = deviceId;

    const error = this.positions.find((position, i) => i !== index && position.device && position.device === deviceId);

    // Two condition to prevent unnecessary redraw
    if (error && !this.positions[index].error) {
      this.positions[index].error = 'ID\'s should not repeat';
    }

    if (!error && this.positions[index].error) {
      this.positions[index].error = undefined;
    }

    const atLeastOndeDeviceChosen = this.positions.find(pos => !!pos.device || pos.skipped);
    this.bindGroupStatuses.bindGroupChanged = !!atLeastOndeDeviceChosen;
  }

  public skip(index: number) {
    this.positions[index].skipped = !this.positions[index].skipped;
    this.positions[index].device = undefined;
    this.positions[index].error = undefined;
    const atLeastOndeDeviceChosen = this.positions.find(pos => !!pos.device || pos.skipped);
    this.bindGroupStatuses.bindGroupChanged = !!atLeastOndeDeviceChosen;
  }

  public calibrationStatus(index: number, status: boolean) {
    this.positions[index].calibrated = status;
  }

  public async bindConfirm() {
    const uncalibrated = this.positions.find(position => !position.done && position.device && !position.calibrated);
    if (uncalibrated && this.native) {
      this.helper.confirm(this.bindAction.bind(this), 'Are you sure you want to bind uncalibrated devices?');
    } else {
      this.bindAction();
    }
  }

  public async bindAction() {
    const devicesIds = this.positions
      .filter(position => !position.skipped && !position.done)
      .map(position => position.device)
      .filter(id => !!id);

    const check = await this.checkDevices(devicesIds as string[]);
    if (!check) {
      return;
    }

    const bind = await this.createAndBind();

    if (!bind) {
      return;
    }

    const attachLabels = await this.attachLabels();
    if (!attachLabels) {
      return;
    }

    const result: BindDevicesListModalResult = {
      done: {
        positions: this.positions.filter(position => !position.device).map(position => position.id) as number[],
        devices: this.positions.map(position => position.device).filter(id => !!id) as string[],
      },
    };

    this.modalController.dismiss(result);
  }

  private async checkDevices(devicesIds: string[]): Promise<boolean> {
    if (!devicesIds.length) {
      return Promise.resolve(true);
    }

    this.helper.showLoading('Devices checking...');

    const { devices } = await this.api.devices.getPositionedDevices(
      {
        device_id: devicesIds,
        force: true,
      },
      this.helper,
    );

    if (!devices) {
      this.helper.hideLoading();
      return Promise.resolve(false);
    }

    for (const [i, position] of this.positions.entries()) {
      if (position.skipped || position.done) {
        continue;
      }

      const found = devices.findIndex(device => device.device_id === position.device);

      if (found < 0) {
        setTimeout(() => this.positions[i].error = 'Device not found');
        this.helper.hideLoading();
        return Promise.resolve(false);
      }

      const positioned = devices.findIndex(device => device.position_id && device.device_id === position.device);

      if (positioned >= 0) {
        setTimeout(() => this.positions[i].error = 'Device is positioned already');
        this.helper.hideLoading();
        return Promise.resolve(false);
      }
    }

    this.helper.hideLoading();
    return Promise.resolve(true);
  }

  private async createAndBind(): Promise<boolean> {
    this.dmStore.loadingBindGroup = [1, this.positions.length];

    for (const [index, position] of this.positions.entries()) {
      if (position.done) {
        continue;
      }

      if (position.skipped) {
        const createdPositionId = await this.api.positions.create(position.data, this.helper);

        if (!createdPositionId) {
          this.dmStore.loadingBindGroup = [0, 0];
          return Promise.resolve(false);
        }

        position.created = true;
        position.id = createdPositionId;
        position.done = true;
      } else {
        const bind = await this.api.devices.bindDeviceToPosition(position.device as string, { position_data: position.data });

        if (bind.error) {
          this.dmStore.loadingBindGroup = [0, 0];
          this.helper.alert(`Device ${position.device} binding failures: ${bind.error || 'Unknown error'}`);
          return Promise.resolve(false);
        } else {
          position.done = true;
          position.created = true;
          position.id = bind.data.position_id;
        }
      }

      this.dmStore.loadingBindGroup = [index + 1, this.positions.length];
    }

    this.dmStore.loadingBindGroup = [0, 0];
    return Promise.resolve(true);
  }

  private async attachLabels(): Promise<boolean | void> {
    const positionsIds = this.positions.map(p => p.id);
    const labelsIds = this.dmStore.selectedLabels.value.map(l => l.id);

    if (!labelsIds.length) {
      return Promise.resolve(true);
    }

    this.helper.showLoading('Attaching labels...');
    const attached = await this.api.positions.attachLabels({
      positions: positionsIds as number[],
      labels: labelsIds,
    }, this.helper);

    this.helper.hideLoading();

    return attached;
  }

  public back() {
    if (this.bindGroupStatuses.bindGroupChanged) {
      this.helper.confirm(() => this.modalController.dismiss(), backButtonMessage);
    } else {
      this.modalController.dismiss();
    }
  }

  public cancelAction() {
    const result: BindDevicesListModalResult = {
      cancel: true,
    };

    this.modalController.dismiss(result);
  }

  public async cancel() {
    this.helper.confirm(this.cancelAction.bind(this), 'Are you sure you want to proceed? The changes you made will be lost');
  }

  public focusEvent(id: string) {
    const item = document.getElementById(id);
    this.content.scrollToPoint(0, item?.offsetTop, 500);
  }
}
