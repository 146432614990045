/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ActionType,
} from './';

/**
 * @export
 * @interface DeviceReplacementsLog
 */
export interface DeviceReplacementsLog {
    /**
     * @type {string}
     * @memberof DeviceReplacementsLog
     */
    id?: string;
    /**
     * @type {ActionType}
     * @memberof DeviceReplacementsLog
     */
    action_type?: ActionType;
    /**
     * `Position Primary Key`
     * @type {number}
     * @memberof DeviceReplacementsLog
     */
    readonly position_id?: number;
    /**
     * `Device Primary Key`. HEX string
     * @type {string}
     * @memberof DeviceReplacementsLog
     */
    readonly device_id: string;
    /**
     * @type {boolean}
     * @memberof DeviceReplacementsLog
     */
    warranty?: boolean;
    /**
     * @type {Date}
     * @memberof DeviceReplacementsLog
     */
    datetime?: Date;
}
