import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PositionUpdateFields, Zone, Group, Position } from 'src/app/model';
import { LatLon } from 'src/app/model/maps';

interface BindDevice {
  [key: number]: string;
}

interface StartFinish {
  start?: LatLon;
  finish?: LatLon;
}

@Injectable({
  providedIn: 'root',
})
export class GroupBindStoreService {
  constructor() { }

  public readonly EXISTINGPOSITIONS = new BehaviorSubject<Position[]>([]);
  readonly existingPositions$ = this.EXISTINGPOSITIONS.asObservable();

  set existingPositions(pos: Position[]) {
    this.EXISTINGPOSITIONS.next(pos);
  }

  get existingPositions() {
    return this.EXISTINGPOSITIONS.getValue();
  }

  public readonly POINTSPOSITIONS = new BehaviorSubject<StartFinish>({ start: undefined, finish: undefined });
  readonly pointsPositions$ = this.POINTSPOSITIONS.asObservable();

  set pointsPositions(startFinish: StartFinish) {
    this.POINTSPOSITIONS.next(startFinish);
  }

  get pointsPositions() {
    return this.POINTSPOSITIONS.getValue();
  }


  public readonly ACCURATEPOSITIONS = new BehaviorSubject<boolean | null>(null);
  readonly accuratePositions$ = this.ACCURATEPOSITIONS.asObservable();

  set accuratePositions(value: boolean) {
    this.ACCURATEPOSITIONS.next(value);
  }

  get accuratePositions() {
    const position = this.ACCURATEPOSITIONS.getValue();

    if (position) {
      return true;
    }

    return false;
  }


  public readonly DEVICESNUMBER = new BehaviorSubject<number>(0);
  readonly devicesNumber$ = this.DEVICESNUMBER.asObservable();

  set devicesNumber(value: number) {
    this.DEVICESNUMBER.next(value);
  }

  get devicesNumber() {
    return this.DEVICESNUMBER.getValue();
  }

  private readonly POSITIONS = new BehaviorSubject<PositionUpdateFields[]>([]);
  readonly positions$ = this.POSITIONS.asObservable();
  get positions(): PositionUpdateFields[] {
    return this.POSITIONS.getValue();
  }
  set positions(value: PositionUpdateFields[]) {
    this.POSITIONS.next(value);
  }


  private readonly BATCH = new BehaviorSubject<Zone | undefined>(undefined);
  get zone(): Zone | undefined {
    return this.BATCH.getValue();
  }
  set zone(value: Zone | undefined) {
    this.BATCH.next(value);
  }


  private readonly GROUP = new BehaviorSubject<Group | undefined>(undefined);
  get group(): Group | undefined {
    return this.GROUP.getValue();
  }
  set group(value: Group | undefined) {
    this.GROUP.next(value);
  }


  private readonly DEVICES = new BehaviorSubject<BindDevice[]>([]);
  get devices(): BindDevice[] {
    return this.DEVICES.getValue();
  }
  set devices(value: BindDevice[]) {
    this.DEVICES.next(value);
  }


  public readonly OPENGROUPBINDTAB = new BehaviorSubject<boolean>(true);
  readonly openGroupBindTab$ = this.OPENGROUPBINDTAB.asObservable();

  set openGroupBindTab(value: boolean) {
    this.OPENGROUPBINDTAB.next(value);
  }

  get openGroupBindTab() {
    return this.OPENGROUPBINDTAB.getValue();
  }

  public clearListsAndSelectedData() {
    this.devicesNumber = 0;
  }
}
