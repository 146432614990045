import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { LocaLstorageService } from 'src/app/store/localstorage.service';


/**
 * @description BarcodeScanProvider helps to scan qr codes with scan method
 */
@Injectable()
export class BarcodeScanProvider {
  constructor(
    private barcodeScanner: BarcodeScanner,
    private localStorageService: LocaLstorageService,
  ) {}

  /**
   * return Promise<string> with result of qr scanning
   * 1) success: result.length > 0 - normal case
   * 2) success: but result.length === 0, this is wrong qr or qr scanning canceled
   * 3) error: str with qr scanning error
   */
  scan(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.barcodeScanner.scan({ torchOn: this.localStorageService.lightUpQR }).then(qrResult => {
        if (qrResult.format === 'QR_CODE') {
          resolve(qrResult.text);
        } else {
          reject('canceled');
        }
      }, (err) => {
        reject(JSON.stringify(err));
      });
    });
  }

}
