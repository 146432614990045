import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Fix for resolving back button actions on bind-group modal
 */
@Injectable({
  providedIn: 'root',
})
export class BindGroupStatuses {
  private readonly QR_SCANNING = new BehaviorSubject<boolean>(false);
  readonly qrScanning$ = this.QR_SCANNING.asObservable();

  private readonly BINDGROUPCHANGED = new BehaviorSubject<boolean>(false);
  readonly bindGroupChanged$ = this.BINDGROUPCHANGED.asObservable();

  get qrScanning(): boolean {
    return this.QR_SCANNING.getValue();
  }
  set qrScanning(val: boolean) {
    this.QR_SCANNING.next(val);
  }

  get bindGroupChanged(): boolean {
    return this.BINDGROUPCHANGED.getValue();
  }
  set bindGroupChanged(val: boolean) {
    this.BINDGROUPCHANGED.next(val);
  }
}
