/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User schema for create
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * @type {string}
     * @memberof UserCreate
     */
    username: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    phone_number: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    sub: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    first_name: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    last_name: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    timezone: string;
    /**
     * @type {string}
     * @memberof UserCreate
     */
    role: UserCreateRoleEnum;
    /**
     * @type {number}
     * @memberof UserCreate
     */
    user_group?: number;
    /**
     * @type {number}
     * @memberof UserCreate
     */
    permissions: number;
    /**
     * @type {Array<number>}
     * @memberof UserCreate
     */
    projects: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserCreateRoleEnum {
    User = 'user',
    Admin = 'admin'
}

