import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { ApiResponse, Project, ProjectCreate, ProjectsFilters, ResponseReason } from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { UserGroup } from 'src/app/model/UserGroup';
import { formatQuery } from './api.utils';
import { HelperProvider } from '../helper/helper';

const DEFAULT_LIMIT = 1000;

interface ResponseUser {
  data: UserGroup[];
}
interface ResponseProject {
  data: Project[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiServiceUsers {
  private readonly api = endpointNames.userManagement;

  private urls = {
    groups: '/groups',
    projects: '/projects',
    users: '/users',
    userSub: (sub: string) => `/users/${encodeURIComponent(String(sub))}`,
  };

  constructor() { }

  public async userGroups(): Promise<{ groups: UserGroup[] }> {
    const response: ApiResponse & ResponseUser = await API.get(this.api, this.urls.groups, {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { groups: response.data };
    }

    return { groups: [] };
  }

  public async projects(filters?: ProjectsFilters): Promise<{ projects: Project[] }> {
    let offset = 0;

    let list: Project[] = [];

    while (list.length === (offset * DEFAULT_LIMIT)) {
      const body = {
        queryStringParameters: formatQuery({
          ...(filters?.owner_id && { owner_id: filters?.owner_id }),
          offset: offset * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      };

      const response: ApiResponse & ResponseProject = await API.get(this.api, this.urls.projects, body).catch(errorHandler);

      if (response.reason === ResponseReason.Ok) {
        list = list.concat(response.data);
        offset += 1;
      } else {
        return { projects: [] };
      }
    }

    return { projects: list };
  }

  public async createProject(project: ProjectCreate, helper: HelperProvider): Promise<number | void> {
    const body = {
      body: project,
    };

    const response: ApiResponse & {data: Project} = await API.post(this.api, this.urls.projects, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data.id;
    } else {
      helper.alert(response.message || 'Error while creating project');
    }
  }

  public async deleteUser(sub: string, helper: HelperProvider): Promise<boolean | void> {
    const response: ApiResponse = await API.del(this.api, this.urls.userSub(sub), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    } else {
      helper.alert(response.message || 'Error while deleting user data');
    }
  }

}
