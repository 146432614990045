export enum LocalSettings {
  MapType = 'mapType',
  AutoCalibration = 'autoCalibration',
  SnapToRoad = 'snapToRoad',
  IntervalPlacing = 'intervalPlacing',
  IntervalMeters = 'intervalMeters',
  CustomIdGeneration = 'customIdGeneration',
  LightUpQR = 'lightUpQR',
  DarkMode = 'darkMode',
  PreEnabledOptions = 'preenabledOptions',
}
