/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User schema for change password
 * @export
 * @interface AdminChangePasswordBody
 */
export interface AdminChangePasswordBody {
    /**
     * Minimum 6 characters, at least one upper case letter, one lower case letter, one digit and one symbol.
     * @type {string}
     * @memberof AdminChangePasswordBody
     */
    password?: string;
    /**
     * false - password is temporary and should be changed after login, true - password is permament. Default true.
     * @type {boolean}
     * @memberof AdminChangePasswordBody
     */
    permanent?: boolean;
}
