/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Encryption keys of device
 * @export
 * @interface EncryptionKeys
 */
export interface EncryptionKeys {
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key1?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key2?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key3?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key4?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key5?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key6?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key7?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key8?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key9?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key10?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key11?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key12?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key13?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key14?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key15?: string;
    /**
     * @type {string}
     * @memberof EncryptionKeys
     */
    key16?: string;
}
