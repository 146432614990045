import { Observable } from 'rxjs';
import * as Sentry from '@sentry/capacitor';
import { delay } from 'rxjs/operators';
import { MapType } from '@capacitor/google-maps';
import { ResponseReason, ApiResponse, GroupType, Zone, Group, Level, Label, Project, UserGroup } from './model';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Timezone } from './providers/timezone';

interface ApiError extends Error {
  response?: {
    data?: {
      message: string;
      reason: ResponseReason;
    };
    status?: number;
  };
}

enum Messages {
  ServerError = 'Server Error',
  NotAuthenticated = 'not authenticated'
}

export const errorHandler = (error: string | ApiError): ApiResponse => {
  if (typeof error === 'object' && error.response?.status === 404) {
    return { message: error.response.data?.message || 'Failed', reason: ResponseReason.NotFound };
  }

  if (typeof error === 'object' && error.response?.status === 400) {
    return { message: error.response.data?.message || 'Failed', reason: ResponseReason.InvalidField };
  }

  if (error === Messages.NotAuthenticated) {
    Auth.signOut();
    return { reason: ResponseReason.Restricted, message: 'You are not authenticated' };
  }

  if (typeof error === 'object' && error?.response?.data?.message) {
    Sentry.captureException(error?.response?.data?.message);
    return error.response.data;
  }

  try {
    Sentry.captureException(JSON.stringify({
      message: Messages.ServerError,
      error: error.toString(),
    }));
  } catch (error) {
    Sentry.captureException(JSON.stringify({
      message: Messages.ServerError,
      error: 'Can\'t parse error',
    }));
  }

  return { message: Messages.ServerError, reason: ResponseReason.ServerError };
};

export const StringFilterHex = /[^a-fA-F0-9]/g;

export const DEFAULT_LIMIT = 5000;

export const groupTypesRadioList = [
  {
    name: GroupType.MarkedParkingBay,
    type: 'radio',
    label: 'Marked Spaces',
    value: GroupType.MarkedParkingBay,
    checked: true,
  },
  {
    name: GroupType.StandaloneCarCounters,
    type: 'radio',
    label: 'Car Counters',
    value: GroupType.StandaloneCarCounters,
  },
  {
    name: GroupType.UnmarkedParkingBay,
    type: 'radio',
    label: 'Unmarked Bay',
    value: GroupType.UnmarkedParkingBay,
  },
];

export const generateCustomId = () => Math.random().toString(36).substring(5);

export const sortByName = (a: Zone | Group | Level | Project | UserGroup, b: Zone | Group | Level | Project | UserGroup) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }

  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }

  return 0;
};

export const sortByLabelName = (a: Label, b: Label) => {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }

  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }

  return 0;
};


export const ITEMS_LIMIT = 40;

// http://localhost:3000/
const dnwaveio = environment.production ? 'https://d.nwave.io/' : 'https://dev.b.nwave.io/';

export const sendSuccessTodnwaveio = (message: { [key: string]: string }) => {
  window.opener?.postMessage(JSON.stringify(message), dnwaveio);
};


export enum EValid {
  valid = 'VALID',
  invalid = 'INVALID',
}

export const mapTypes = [
  {
    name: 'Map',
    value: MapType.Normal,
  },
  {
    name: 'Sattelite',
    value: MapType.Hybrid,
  },
];

// Scroll issue fix in ionic-selectable
// see: https://nwaveio.atlassian.net/browse/BNIV-1674
export const innerScrollEl = () => {
  return document.querySelector('ionic-selectable-modal ion-content')?.shadowRoot?.querySelector('.inner-scroll');
};
export const returnScrollPosition = (el: Element, val: number) => {
  setTimeout(() => {
    const itemEl = document.querySelector('ionic-selectable-modal ion-content ion-item');
    el.scrollTo(0, val + (itemEl?.clientHeight || 0));
  }, 200);
};
export const scrollToSelected = () => {
  setTimeout(() => {
    const itemEl = document.querySelector('ionic-selectable-modal ion-content ion-item.ionic-selectable-item-is-selected') as HTMLElement;
    if (itemEl) {
      const scrollEl = innerScrollEl();
      scrollEl?.scrollTo(0, itemEl.offsetTop - 200);
    }
  }, 100);
};

// Fix back button issue
// see: https://nwaveio.atlassian.net/browse/BNIV-1669
export const backGroupModalClass = 'bind-group-modal';


export const backButtonMessage = 'Are you sure you want to go back? The changes you made will be lost';

/**
 * Preventing duplicates in the infinite scroll
 * @param page
 * @param size
 * @param timeout
 */
export function getListAsync<T extends Zone | Group | Level | Label | UserGroup | Project | Timezone>(
  list: T[],
  page: number,
  size: number,
  timeout = 1000,
): Observable<T[]> {
  return new Observable<T[]>(observer => {
    const part = page * size;
    observer.next(list.slice(part, part + size));
    observer.complete();
  }).pipe(delay(timeout));
}

export function setAppTheme(theme: 'dark' | 'light') {
  document.body.setAttribute('data-theme', theme);
}

export function isInitThemeDark(): boolean {
  const systemDark = window.matchMedia('(prefers-color-scheme: dark)');

  return systemDark.matches;
}
