/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User schema for updating by Admin
 * @export
 * @interface UserUpdateAdmin
 */
export interface UserUpdateAdmin {
    /**
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    first_name: string;
    /**
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    last_name: string;
    /**
     * @type {number}
     * @memberof UserUpdateAdmin
     */
    permissions: number;
    /**
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    timezone: string;
    /**
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    phone_number: string;
    /**
     * @type {number}
     * @memberof UserUpdateAdmin
     */
    user_group: number;
    /**
     * @type {string}
     * @memberof UserUpdateAdmin
     */
    role: UserUpdateAdminRoleEnum;
    /**
     * @type {Array<number>}
     * @memberof UserUpdateAdmin
     */
    projects: Array<number>;
}

/**
 * @export
 * @enum {string}
 */
export enum UserUpdateAdminRoleEnum {
    User = 'user',
    Admin = 'admin'
}

