import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

/**
 * Helper service for managing UI in application
 */
@Injectable()
export class HelperProvider {
  private loading: HTMLIonLoadingElement;
  public isLoading = false;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertController: AlertController,
  ) { }

  /**
   * show native loading
   */
  async showLoading(text?: string): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.loading = await this.loadingCtrl.create({
      ...(text && { message: text }),
    });
    this.loading.present();
  }

  /**
   * hide loading. Timeout needs for preventing error when loadingCtrl is null
   */
  hideLoading = () => setTimeout(() => {
    if (!this.isLoading) {
      return;
    }

    this.isLoading = false;
    this.loading.dismiss();
  }, 100)

  public async showToast(text: string) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 2000,
    });
    toast.present();
  }

  public async alert(msg: string, title?: string) {
    const alert = await this.alertController.create({
      header: title || 'Failure',
      message: msg,
      buttons: ['OK'],
    });

    return alert.present();
  }

  public async confirm(handler: () => void, message?: string, header?: string, cancel?: () => void, confirmText = 'Confirm') {
    const alert = await this.alertController.create({
      header: header ? header : 'Confirmation',
      message: message ? message : 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          ...(cancel && { handler: () => cancel() }),
        }, {
          text: confirmText,
          handler: () => handler(),
        },
      ],
    });

    await alert.present();
  }
}
