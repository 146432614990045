/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GroupType,
} from './';

/**
 * @export
 * @interface GroupUpdateFields
 */
export interface GroupUpdateFields {
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof GroupUpdateFields
     */
    name: string;
    /**
     * @type {GroupType}
     * @memberof GroupUpdateFields
     */
    type: GroupType;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof GroupUpdateFields
     */
    custom_id: string;
    /**
     * `Zone Primary Key`
     * @type {number}
     * @memberof GroupUpdateFields
     */
    zone_id: number;
    /**
     * `Level Primary Key`
     * @type {number}
     * @memberof GroupUpdateFields
     */
    readonly level_id: number | null;
}
