export interface LatLon {
  lat: number;
  lon: number;
}

export interface CustomMarker {
  key: string;
  latLon: LatLon;
  options?: {
    color: string
  };
}

export type CustomMarkers = CustomMarker[];

export const movingDistance = 0.75; // 1 meter
