import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  LevelsFilters,
  ResponseReason,
  Level,
  LevelUpdateFields,
  ResponseLevels,
  ResponseLevel
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { formatQuery } from './api.utils';
import { HelperProvider } from '../helper/helper';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceLevels {
  private readonly dmApi = endpointNames.deviceManagement;

  private urls = {
    levels: '/levels',
    levelId: (id: number) => `/level/${encodeURIComponent(String(id))}`,
  };

  constructor() { }

  public async fetch(filters?: LevelsFilters): Promise<{ levels?: Level[]; error?: string }> {
    const body = {
      queryStringParameters: formatQuery({
        zone_id: filters?.zone_id,
        floor_number: filters?.floor_number,
      }),
    };

    const response: ResponseLevels = await API.get(this.dmApi, this.urls.levels, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { levels: response.data };
    } else {
      return { levels: [] };
    }
  }

  public async getLevelById(levelId: number, helper?: HelperProvider): Promise<Level | undefined> {
    const response: ResponseLevel = await API.get(this.dmApi, this.urls.levelId(levelId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      helper?.alert(response.data as any as string || 'Try again later');
      return;
    }
  }

  public async create(level: LevelUpdateFields, helper: HelperProvider): Promise<Level | void> {
    const body = {
      body: level,
    };

    const response: ResponseLevel = await API.post(this.dmApi, this.urls.levels, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      helper.alert(response.message || 'Error while creating level');
    }
  }
}
