import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  ApiResponse,
  Position,
  ResponseReason,
  PositionsFilters,
  PositionUpdateFields,
  ResponsePositionsWithTotal,
  ResponsePosition,
  ResponsePositions,
  PositionsLabelsAttachBody
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { BehaviorSubject } from 'rxjs';
import { HelperProvider } from '../helper/helper';
import { errorHandler, DEFAULT_LIMIT } from 'src/app/app.utils';
import { formatQuery } from './api.utils';

@Injectable({
  providedIn: 'root',
})
export class ApiServicePositions {
  private readonly dmApi = endpointNames.deviceManagement;

  private positionUrls = {
    find: '/positions/find',
    positions: '/positions',
    positionId: (id: number) => `/position/${encodeURIComponent(String(id))}`,
    attachLabels: '/positions/labels/attach',
    detachLabels: '/positions/labels/detach',
  };

  constructor() { }

  public async getPositions(
    filters: PositionsFilters,
    helper: HelperProvider,
    loader?: BehaviorSubject<number>,
  ): Promise<{ positions: Position[]; }> {
    let offset = 0;

    let list: Position[] = [];

    while (list.length === (offset * DEFAULT_LIMIT)) {
      const body = {
        queryStringParameters: formatQuery({
          position_id: filters.position_id,
          bound: filters.bound,
          group_id: filters.group_id,
          zone_id: filters.zone_id,
          offset: offset * DEFAULT_LIMIT,
          limit: DEFAULT_LIMIT,
        }),
      };

      const response: ResponsePositionsWithTotal = await API.get(this.dmApi, this.positionUrls.find, body).catch(errorHandler);

      if (response.reason === ResponseReason.Ok) {
        list = list.concat(response.data);
        offset += 1;
        if (loader) {
          loader.next(loader.getValue() + offset);
        }
      } else {
        helper.alert(response.message, 'Getting positions error');
        break;
      }
    }

    return { positions: list };
  }

  public async getPosition(positionId: number, helper?: HelperProvider): Promise<Position | void> {
    const response: ResponsePosition = await API.get(this.dmApi, this.positionUrls.positionId(positionId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data as Position;
    }

    if (helper) {
      helper.alert(response.message || 'Error while getting position');
    }
  }

  public async delete(positionId: number, helper?: HelperProvider): Promise<boolean | void> {
    const response: ApiResponse = await API.del(this.dmApi, this.positionUrls.positionId(positionId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    }

    if (helper) {
      helper.alert(response.message || 'Error while deleting position');
    }
  }

  public async create(position: PositionUpdateFields, helper?: HelperProvider): Promise<number | void> {
    const body = {
      body: [position],
    };

    const response: ResponsePositions = await API.post(this.dmApi, this.positionUrls.positions, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data[0].id;
    }

    if (helper) {
      helper.alert(response.message || 'Error while creating position');
    }
  }

  public async update(position: PositionUpdateFields, id: number, helper?: HelperProvider): Promise<boolean | void> {
    const body = {
      body: position,
    };

    const response: ApiResponse = await API.put(this.dmApi, this.positionUrls.positionId(id), body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    }

    if (helper) {
      helper.alert(response.message || 'Error while updating position');
    }
  }

  public async attachLabels(body: PositionsLabelsAttachBody, helper?: HelperProvider): Promise<boolean | void> {
    const response: ApiResponse = await API.post(this.dmApi, this.positionUrls.attachLabels, { body }).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    }

    if (helper) {
      helper.alert(response.message || 'Error while attaching labels to position');
    }
  }

  public async detachLabels(body: PositionsLabelsAttachBody, helper?: HelperProvider): Promise<boolean | void> {
    const response: ApiResponse = await API.post(this.dmApi, this.positionUrls.detachLabels, { body }).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return true;
    }

    if (helper) {
      helper.alert(response.message || 'Error while detaching labels from position');
    }
  }
}
