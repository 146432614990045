import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { Amplify, Auth } from 'aws-amplify';

import amplify from './aws-exports';
import { LocaLstorageService } from './app/store/localstorage.service';
import { Storage } from '@ionic/storage-angular';
import { DmStoreService } from './app/store/dm-store.service';
import { BarcodeScanProvider, HelperProvider } from './app/providers';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),
    provideRouter(routes),
    LocaLstorageService,
    Storage,
    DmStoreService,
    HelperProvider,
    BarcodeScanProvider,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
  ],
});

defineCustomElements(window);

const APIoptions = {
  API: {
    endpoints: environment.endpoints.map(e => ({
      name: e.endpointName,
      endpoint: e.endpoint,
      custom_header: async () => {
        /**
         * for cognito login param:
         * 'X-Api-Key': (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken
         */
        return {
          'X-Api-Key': (await Auth.currentAuthenticatedUser()).signInUserSession.idToken.jwtToken,
          'Content-Type': 'application/json',
        };
      },
      region: environment.region,
    })),
  },
};

Amplify.configure({ ...amplify, ...APIoptions });

Sentry.init(
  {
    dsn: 'https://8e66b131371f498498536a29853279aa@o328734.ingest.sentry.io/5997478',

    // Set your release version, such as "getsentry@1.0.0"
    release: 'splace2@release',
    // Set your dist version, such as "1"
    dist: '3',
    environment: environment.production ? 'production' : 'staging',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new SentryAngular.BrowserTracing({
        routingInstrumentation: SentryAngular.routingInstrumentation,
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init,
);
