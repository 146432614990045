import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  Group,
  ResponseReason,
  GroupsFilters,
  GroupCreateFields,
  ResponseGroupsWithTotal,
  ResponseGroup,
  ResponseGroups
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { formatQuery } from './api.utils';
import { HelperProvider } from '../helper/helper';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceGroups {
  private readonly dmApi = endpointNames.deviceManagement;

  private groupUrls = {
    groupsFind: '/groups/find',
    groups: '/groups',
    groupId: (id: number) => `/group/${encodeURIComponent(String(id))}`,
  };

  constructor() { }

  public async fetchGroups(filters?: GroupsFilters): Promise<{ groups?: Group[]; error?: string }> {
    const body = {
      queryStringParameters: formatQuery({
        zone_id: filters?.zone_id,
        level_id: filters?.level_id,
      }),
    };

    const response: ResponseGroupsWithTotal = await API.get(this.dmApi, this.groupUrls.groupsFind, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { groups: response.data };
    } else {
      return { groups: [] };
    }
  }

  public async getGroupById(groupId: number, helper?: HelperProvider): Promise<Group | undefined> {
    const response: ResponseGroup = await API.get(this.dmApi, this.groupUrls.groupId(groupId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      helper?.alert((response.data as any as string) || 'Try again later');
      return;
    }
  }

  public async create(group: GroupCreateFields, helper: HelperProvider): Promise<number | void> {
    const body = {
      body: [group],
    };

    const response: ResponseGroups = await API.post(this.dmApi, this.groupUrls.groups, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data[0].id;
    } else {
      helper.alert(response.message || 'Error while creating group');
    }
  }
}
