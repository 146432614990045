/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * `Position Primary Key`
     * @type {number}
     * @memberof Position
     */
    readonly id: number;
    /**
     * @type {number}
     * @memberof Position
     */
    lat: number;
    /**
     * @type {number}
     * @memberof Position
     */
    lon: number;
    /**
     * @type {Date}
     * @memberof Position
     */
    creation_date?: Date;
    /**
     * `Group Primary Key`
     * @type {number}
     * @memberof Position
     */
    readonly group_id: number;
    /**
     * @type {number}
     * @memberof Position
     */
    group_inner_id: number;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof Position
     */
    custom_id?: string;
    /**
     * ***Position network_id***
     * @type {string}
     * @memberof Position
     */
    network_id?: string;
    /**
     * `Level Primary Key`
     * @type {number}
     * @memberof Position
     */
    readonly level_id?: number;
}
