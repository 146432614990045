// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { environment } from './environments/environment';

const awsmobile = environment.production ? {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:d217ae77-8660-4712-bb18-8d1122aa835c",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_oGDe1ZJwz",
  "aws_user_pools_web_client_id": "2a22imqu44e29vr827frb9q179",
  "oauth": {}
} : {
  "aws_cognito_identity_pool_id": 'eu-west-2:79e39cc8-2260-49c5-b901-7b2ba9829127',
  "aws_cognito_region": 'eu-west-2',
  "aws_user_pools_id": 'eu-west-2_rRJjS5hoO',
  "aws_user_pools_web_client_id": '24vbvpe86biafui1p3qkcvs23j',
  "oauth": {}
};

export default awsmobile;
