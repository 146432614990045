/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * @type {number}
     * @memberof Project
     */
    owner_id: number;
    /**
     * @type {number}
     * @memberof Project
     */
    id: number;
    /**
     * @type {string}
     * @memberof Project
     */
    timezone: string | null;
    /**
     * @type {boolean}
     * @memberof Project
     */
    is_default: boolean;
}
