/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserWithStatusAllOf
 */
export interface UserWithStatusAllOf {
    /**
     * false - email is not confirmed or user not found in Cognito user pool, true - email is confirmed
     * @type {boolean}
     * @memberof UserWithStatusAllOf
     */
    confirmed: boolean;
}
