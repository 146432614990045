/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Label,
} from './';

/**
 * @export
 * @interface PositionWithLevelAllOf
 */
export interface PositionWithLevelAllOf {
    /**
     * ***Position group floor number***
     * @type {number}
     * @memberof PositionWithLevelAllOf
     */
    floor_number?: number;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof PositionWithLevelAllOf
     */
    level_name?: string;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof PositionWithLevelAllOf
     */
    group_name: string;
    /**
     * `Zone Primary Key`
     * @type {number}
     * @memberof PositionWithLevelAllOf
     */
    zone_id: number;
    /**
     * @type {Array<Label>}
     * @memberof PositionWithLevelAllOf
     */
    labels?: Array<Label>;
}
