import { endpointNames, VERSION, SENTRY_URL } from './common';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: `${VERSION} development`,
  production: false,
  region: 'eu-west-2',
  sentry: SENTRY_URL,
  endpoints: [
    {
      endpointName: endpointNames.deviceManagement,
      endpoint: 'https://g29tm236h4.execute-api.eu-west-2.amazonaws.com/dev',
    },
    {
      endpointName: endpointNames.userManagement,
      endpoint: 'https://k1r6bh83ya.execute-api.eu-west-2.amazonaws.com/v1',
    },
  ],
  GoogleMapApiKey: 'AIzaSyD4DIKNMXIEJP5BzFpq7PNl53lzcbKVwys',
  GoogleMapApiKeyWeb: 'AIzaSyASEp_QBFz7c0rwo6tABKfENGwkEvXNXE4',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
