<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()"><ion-icon name="arrow-back-outline"></ion-icon></ion-button>
    </ion-buttons>
    <ion-title>Bind group of devices</ion-title>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="cancel()">cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" [scrollEvents]="true" padding>
  <ion-list class="keyboard-fix">
    <ion-item-group *ngFor="let position of positions, let i = index" (click)="focusEvent('item' + i)" id="{{'item' + i}}">
      <ion-item class="clear-item-interactive-disabled">
        <ion-input
          *ngIf="position.skipped"
          disabled
          label="Empty Position {{position.data.group_inner_id}}"
          labelPlacement="floating"
          placeholder=""
        ></ion-input>
        <app-device-input
          *ngIf="!position.skipped"
          [error]="position.error || ''"
          (newDevice)="attachDeviceToPosition(i, $event)"
          [disabled]="!!position.done"
          [index]="position.data.group_inner_id"
          (calibrationStatus)="calibrationStatus(i, $event)"
        ></app-device-input>

        <ion-button
          fill="clear"
          slot="end"
          class="ion-margin-start ion-margin-vertical"
          (click)="skip(i)"
          [disabled]="position.done"
          [color]="position.skipped ? 'success' : 'primary'"
        >
          <ion-icon slot="icon-only" name="{{position.skipped ? 'add-circle-outline' : 'close-outline'}}"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-item-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button expand="full" (click)="bindConfirm()" [disabled]="disabledBind()">create and bind</ion-button>
  </ion-toolbar>
</ion-footer>
