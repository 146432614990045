/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { ModalController } from '@ionic/angular';
import { HelperProvider } from 'src/app/providers';
import { backButtonMessage, backGroupModalClass } from '../../app.utils';
import { DmStoreService } from 'src/app/store/dm-store.service';
import { GroupBindStoreService } from 'src/app/store/group-bind-store.service';
import { DeviceBindStoreService } from 'src/app/store/device-bind-store.service';
import { BindGroupStatuses } from 'src/app/store/bindGroupStatuses';

@Injectable()
export class BackButtonHandlerProvider {
  constructor(
    private modalController: ModalController,
    private dmStoreService: DmStoreService,
    private groupBindStore: GroupBindStoreService,
    private deviceBindStore: DeviceBindStoreService,
    private router: Router,
    private helper: HelperProvider,
    private bindGroupStatuses: BindGroupStatuses,
  ) { }

  public cancelAccuratePositions = () => {
    this.groupBindStore.accuratePositions = false;
    this.deviceBindStore.openBindFromUrl = true;
  }
  public cancelAccuratePosition = () => {
    this.deviceBindStore.accurateDevicePosition = false;
    this.deviceBindStore.openBindFromUrl = true;
  }
  private restartAccurate = () => this.groupBindStore.accuratePositions = true;

  public backAccuratePositions(restart?: any) {
    if (this.groupBindStore.pointsPositions.finish) {
      this.groupBindStore.pointsPositions = {
        ...this.groupBindStore.pointsPositions,
        finish: undefined,
      };
      if (restart) {
        restart();
      }
      return;
    }

    if (this.groupBindStore.pointsPositions.start) {
      this.restartAccurate();
    } else {
      this.cancelAccuratePositions();
    }
  }

  /**
   * Native back button events
   */
   public async backButton(restart?: any) {
    const scanner = document.querySelector('ion-app')?.classList.contains('scanner-active');

    if (scanner) {
      return;
    }

    if (this.router.url.includes('auth')) {
      return;
    }

    if (this.dmStoreService.device) {
      this.dmStoreService.deselectDevice();

      return;
    }

    if (this.dmStoreService.position) {
      this.dmStoreService.deselectPosition();

      return;
    }

    const isModalOpened = await this.modalController.getTop();
    if (isModalOpened) {
      if (isModalOpened.classList.contains(backGroupModalClass)) {
        if (this.bindGroupStatuses.qrScanning) {
          return;
        }

        if (this.bindGroupStatuses.bindGroupChanged) {
          this.helper.confirm(() => isModalOpened.dismiss(), backButtonMessage);
          return;
        }
      }

      isModalOpened.dismiss();
      return;
    }

    if (this.groupBindStore.accuratePositions) {
      this.backAccuratePositions(restart);
      return;
    }

    if (this.deviceBindStore.accurateDevicePosition) {
      this.cancelAccuratePosition();
      return;
    }

    App.exitApp();
  }
}
