/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    DamageLogByDate,
    DeviceDamagesLog,
    DeviceReplacementsLog,
    ReplacementLogByDate,
} from './';

/**
 * @export
 * @interface HistoryStatistic
 */
export interface HistoryStatistic {
    /**
     * @type {Array<DeviceReplacementsLog>}
     * @memberof HistoryStatistic
     */
    replacement_history?: Array<DeviceReplacementsLog>;
    /**
     * @type {Array<DeviceDamagesLog>}
     * @memberof HistoryStatistic
     */
    damage_history?: Array<DeviceDamagesLog>;
    /**
     * @type {Array<ReplacementLogByDate>}
     * @memberof HistoryStatistic
     */
    replacement_log_by_date?: Array<ReplacementLogByDate>;
    /**
     * @type {Array<DamageLogByDate>}
     * @memberof HistoryStatistic
     */
    damage_log_by_date?: Array<DamageLogByDate>;
}
