<ion-grid class="ion-no-padding" fixed>
  <ion-row align-items-start class="ion-justify-content-center ion-align-items-center">
    <ion-col class="ion-no-padding ion-no-margin">
      <ion-input
        [(ngModel)]="deviceId"
        (ionInput)="onChange()"
        inputmode="url"
        required
        [disabled]="disabled"
        label="Device ID {{index || ''}}"
        labelPlacement="floating"
        placeholder=""
      ></ion-input>
    </ion-col>
    <ion-col align-self-end size="auto" *ngIf="native">
      <ion-button
        (click)="scan()"
        fill="clear"
        [disabled]="disabled"
        size="default"
      >
        <ion-icon slot="icon-only" name="scan"></ion-icon>
      </ion-button>
      <ion-button
        (click)="calibrate()"
        [color]="calibrated ? 'success' : 'danger'"
        class="btn-square ion-margin-start"
        [disabled]="disabled || !deviceId"
        *ngIf="!disabledCalibration"
        size="default"
      >
        <ion-icon slot="icon-only" name="build"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="error">
    <ion-text color="danger">
      <h4>{{error}}</h4>
    </ion-text>
  </ion-row>
</ion-grid>
