/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ResponseModel
 */
export interface ResponseModel {
    /**
     * @type {string}
     * @memberof ResponseModel
     */
    message?: string;
    /**
     * @type {string}
     * @memberof ResponseModel
     */
    reason?: ResponseModelReasonEnum;
    /**
     * @type {number}
     * @memberof ResponseModel
     */
    total?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ResponseModelReasonEnum {
    Ok = 'ok',
    Created = 'created',
    InvalidField = 'invalid_field',
    NoData = 'no_data',
    NotFound = 'not_found',
    NotImplemented = 'not_implemented',
    Restricted = 'restricted',
    ServerError = 'server_error'
}

