import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Position, Device } from 'src/app/model';

@Injectable({
  providedIn: 'root',
})
export class DeviceBindStoreService {
  constructor() { }

  public readonly ACCURATEDEVICEPOSITION = new BehaviorSubject<boolean | null>(null);
  readonly accurateDevicePosition$ = this.ACCURATEDEVICEPOSITION.asObservable();

  get accurateDevicePosition() {
    return this.ACCURATEDEVICEPOSITION.getValue();
  }
  set accurateDevicePosition(value) {
    this.ACCURATEDEVICEPOSITION.next(value);
  }

  private readonly BATCH = new BehaviorSubject<number | undefined>(undefined);
  get zone(): number | undefined {
    return this.BATCH.getValue();
  }
  set zone(value: number | undefined) {
    this.BATCH.next(value);
  }

  private readonly GROUP = new BehaviorSubject<number | undefined>(undefined);
  get group(): number | undefined {
    return this.GROUP.getValue();
  }
  set group(value: number | undefined) {
    this.GROUP.next(value);
  }

  private readonly POSITIONSINGROUP = new BehaviorSubject<Position[]>([]);
  get positionsInGroup(): Position[] {
    return this.POSITIONSINGROUP.getValue();
  }
  set positionsInGroup(value: Position[]) {
    this.POSITIONSINGROUP.next(value);
  }

  private readonly DEVICE = new BehaviorSubject<Device | null>(null);
  get device(): Device | null {
    return this.DEVICE.getValue();
  }
  set device(value: Device | null) {
    this.DEVICE.next(value);
  }

  public readonly NEWCUSTOMID = new BehaviorSubject<string | undefined>(undefined);
  readonly newCustomId$ = this.NEWCUSTOMID.asObservable();

  get newCustomId(): string | undefined {
    return this.NEWCUSTOMID.getValue();
  }
  set newCustomId(value: string | undefined) {
    this.NEWCUSTOMID.next(value);
  }

  /**
   * use existing position for device bind
   */
  public readonly EXISTINGPOSITION = new BehaviorSubject<number | undefined>(undefined);
  readonly existingPositionForBind$ = this.EXISTINGPOSITION.asObservable();

  get existingPositionForBind(): number | undefined {
    return this.EXISTINGPOSITION.getValue();
  }
  set existingPositionForBind(value: number | undefined) {
    this.EXISTINGPOSITION.next(value);
  }

  /**
   * Event for d.nwave.io redirect
   */
  public readonly OPENBINDFROMURL = new BehaviorSubject<boolean>(false);
  readonly openBindFromUrl$ = this.OPENBINDFROMURL.asObservable();

  get openBindFromUrl(): boolean {
    return this.OPENBINDFROMURL.getValue();
  }
  set openBindFromUrl(value: boolean) {
    this.OPENBINDFROMURL.next(value);
  }

  public readonly DEVICESKIPPED = new BehaviorSubject<boolean>(false);
  readonly deviceSkipped$ = this.DEVICESKIPPED.asObservable();

  get deviceSkipped(): boolean {
    return this.DEVICESKIPPED.getValue();
  }
  set deviceSkipped(value: boolean) {
    this.DEVICESKIPPED.next(value);
  }
}
