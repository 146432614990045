/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    LabelCount,
} from './';

/**
 * @export
 * @interface Zone
 */
export interface Zone {
    /**
     * `Zone Primary Key`
     * @type {number}
     * @memberof Zone
     */
    readonly id: number;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof Zone
     */
    name: string;
    /**
     * Owner ID from cognito user pool
     * @type {number}
     * @memberof Zone
     */
    owner_id: number;
    /**
     * Flag defines whether zone participates in statistics collection
     * @type {boolean}
     * @memberof Zone
     */
    live_status: boolean;
    /**
     * @type {number}
     * @memberof Zone
     */
    project_id: number;
    /**
     * @type {Date}
     * @memberof Zone
     */
    creation_date?: Date;
    /**
     * @type {Array<LabelCount>}
     * @memberof Zone
     */
    labels?: Array<LabelCount>;
}
