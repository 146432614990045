/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    GroupType,
    LabelCount,
} from './';

/**
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * `Group Primary Key`
     * @type {number}
     * @memberof Group
     */
    readonly id: number;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * @type {GroupType}
     * @memberof Group
     */
    type: GroupType;
    /**
     * `Zone Primary Key`
     * @type {number}
     * @memberof Group
     */
    readonly zone_id: number;
    /**
     * @type {Date}
     * @memberof Group
     */
    creation_date?: Date;
    /**
     * Names String. For example zone name, group name or custom_id
     * @type {string}
     * @memberof Group
     */
    custom_id?: string;
    /**
     * `Level Primary Key`
     * @type {number}
     * @memberof Group
     */
    readonly level_id?: number;
    /**
     * ***Position group floor number***
     * @type {number}
     * @memberof Group
     */
    floor_number?: number;
    /**
     * @type {Array<LabelCount>}
     * @memberof Group
     */
    labels?: Array<LabelCount>;
}
