import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  Zone,
  ResponseReason,
  ZoneUpdateFields,
  ResponseZone,
  ResponseZones,
  ZonesFilters
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { HelperProvider } from 'src/app/providers/helper/helper';
import { formatQuery } from './api.utils';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceZones {
  private readonly dmApi = endpointNames.deviceManagement;

  private urls = {
    zonesFind: '/zones/find',
    zones: '/zones',
    zoneId: (id: number) => `/zone/${encodeURIComponent(String(id))}`,
  };

  constructor() {}

  public async create(zone: ZoneUpdateFields, helper: HelperProvider): Promise<number | void> {
    const body = {
      body: zone,
    };

    const response: ResponseZone = await API.post(this.dmApi, this.urls.zones, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data.id;
    } else {
      helper.alert(response.message || 'Error while creating zone');
    }
  }

  public async fetchZones(filters: ZonesFilters): Promise<{ zones: Zone[] }> {
    const body = {
      queryStringParameters: formatQuery({
        project_id: filters.project_id,
      }),
    };
    const response: ResponseZones = await API.get(this.dmApi, this.urls.zonesFind, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { zones: response.data };
    }

    return { zones: [] };
  }

  public async getZoneById(zoneId: number, helper?: HelperProvider): Promise<Zone | undefined> {
    const response: ResponseZone = await API.get(this.dmApi, this.urls.zoneId(zoneId), {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      if (helper) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        helper.alert(response.data as any as string || 'Try again later');
      }
      return;
    }
  }
}
