import { Injectable } from '@angular/core';
import { HelperProvider } from '../helper/helper';

enum AdvertisingCheks {
  user_cancelled = 'user_cancelled',
  ok = 'OK',
  not_found = 'not_found'
}

type AdvertisingError = string;

interface CalibrationStatus {
  isActivityDone?: boolean;
  extras?: {
    result: AdvertisingCheks | AdvertisingError
  };
}

@Injectable({
  providedIn: 'root',
})
export class CalibrateService {
  constructor(private helper: HelperProvider) { }

  public start(deviceID: string, callback: (res: boolean | undefined) => void, notWorking: () => void): void {
    const extras = [
      {
        name: 'in_hex_string_network_id',
        value: deviceID,
        dataType: 'String',
      },
    ];

    try {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      window['plugins'].launcher.launch(
        { uri: `devicemanagement://device/${deviceID}/calibrate`, extras }, (res: CalibrationStatus) => {
          if (res.isActivityDone && res.extras) {
            const result = res?.extras?.result;
            switch (result) {
              case AdvertisingCheks.ok:
                callback(true);
                break;
              case AdvertisingCheks.not_found:
                this.helper.alert('Device not found');
                callback(false);
                break;
              case AdvertisingCheks.user_cancelled:
                callback(undefined);
                break;
              default:
                this.helper.alert(result || 'Device calibration failed');
                callback(false);
                break;
            }
          } else {
            callback(undefined);
          }
        }, () => {
          this.helper.alert('Can\'t start calibration');
          callback(false);
        },
      );
    } catch (err) {
      notWorking();
    }
  }

}
