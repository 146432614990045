import { Component, OnInit } from '@angular/core';
import { IonicModule, ToastController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-connections-status',
  templateUrl: './connections-status.component.html',
  styleUrls: ['./connections-status.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
  providers: [ToastController],
})
export class ConnectionStatusComponent implements OnInit {
  private lostConnectionToast: HTMLIonToastElement;
  private restoredConnectionToast: HTMLIonToastElement;
  private connected = true;

  constructor(private toastController: ToastController) { }

  async ngOnInit() {
    this.lostConnectionToast = await this.toastController.create({
      cssClass: 'toast-danger',
      duration: 5000,
      message: 'Lost internet connection...',
      position: 'bottom',
    });

    this.restoredConnectionToast = await this.toastController.create({
      message: 'Internet connection restored',
      duration: 5000,
      cssClass: 'toast-success',
      position: 'bottom',
    });

    Network.addListener('networkStatusChange', (status) => this.showToast(status.connected));
  }

  private async showToast(status: boolean) {
    if (status === this.connected) {
      return;
    }

    this.connected = status;

    if (status) {
      this.restoredConnectionToast.present();
    } else {
      this.lostConnectionToast.present();
    }
  }
}
