import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  ResponseReason,
  Label,
  ResponseLabels,
  LabelCreateFields,
  ResponseLabel
} from 'src/app/model';
import { endpointNames } from 'src/environments/common';
import { errorHandler } from 'src/app/app.utils';
import { HelperProvider } from '../helper/helper';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceLabels {
  private readonly dmApi = endpointNames.deviceManagement;

  private urls = {
    labels: '/labels',
  };

  constructor() { }

  public async fetch(): Promise<{ labels?: Label[]; error?: string }> {
    const response: ResponseLabels = await API.get(this.dmApi, this.urls.labels, {}).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return { labels: response.data };
    } else {
      return { labels: [] };
    }
  }

  public async create(label: LabelCreateFields, helper: HelperProvider): Promise<Label | void> {
    const body = {
      body: label,
    };

    const response: ResponseLabel = await API.post(this.dmApi, this.urls.labels, body).catch(errorHandler);

    if (response.reason === ResponseReason.Ok) {
      return response.data;
    } else {
      helper.alert(response.message || 'Error while creating label');
    }
  }
}
