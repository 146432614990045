import { Injectable } from '@angular/core';
import { LatLon, movingDistance, CustomMarker } from 'src/app/model/maps';
import { LocaLstorageService } from 'src/app/store/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class MapsUtils {
  private oldPolyline?: google.maps.Polyline;
  private invertedRoute: boolean;

  constructor(
    private optionsStore: LocaLstorageService,
  ) { }

  /**
   *
   * @param markers an array of marker objects you want to move. Each marker should have geolocation key with lat and lng properties in it
   * @param angle an angle (max 360) that defines the direction in what you want to move markers
   * @param distance distance you want to move your markers on
   *
   * @description returns new array of moved markers
   */
  moveMarker(markers: LatLon[], angle: number, distance: number = movingDistance): LatLon[] {
    const result: LatLon[] = [];
    for (const marker of markers) {
      if (marker.lat && marker.lon) {
        const item = new google.maps.LatLng(marker.lat, marker.lon);
        const newItem = google.maps.geometry.spherical.computeOffset(item, distance, angle);
        marker.lat = +newItem.lat();
        marker.lon = +newItem.lng();
      }

      result.push(marker);
    }
    return result;
  }

  moveCustomMarker(markers: CustomMarker[], angle: number, distance: number = movingDistance): CustomMarker[] {
    const result: CustomMarker[] = [];
    for (const marker of markers) {
      if (marker.latLon.lat && marker.latLon.lon) {
        const item = new google.maps.LatLng(marker.latLon.lat, marker.latLon.lon);
        const newItem = google.maps.geometry.spherical.computeOffset(item, distance, angle);
        marker.latLon.lat = +newItem.lat();
        marker.latLon.lon = +newItem.lng();
      }

      result.push(marker);
    }
    return result;
  }

  movePolyline(angle: number, distance: number = movingDistance) {
    if (!this.oldPolyline) {
      return undefined;
    }

    const points = [];
    for (let i = 0; i < this.oldPolyline.getPath().getLength(); i++) {
      const point = this.oldPolyline.getPath().getAt(i);
      const pointCoords = new google.maps.LatLng(point.lat(), point.lng());
      const newItem = google.maps.geometry.spherical.computeOffset(pointCoords, distance, angle);
      points.push(newItem);
    }
    this.oldPolyline.setPath(points);

    return this.oldPolyline;
  }

  /**
   * @param map the google map object you are working with
   * @param start an object that has object with lat and lon coordinates
   * @param finish an object that has object with lat and lon coordinates
   * @param amountOfMarkers number of markers you want to draw
   * @description this function works with getMilestone function below.
   * Returns a promise of preset amount of markers between start and finish.
   * Works in 2 modes: with enabled and disabled interval placing.
   * If interval placing is enabled, it uses intervalPlacingInterval variable to set the interval.
   * In this mode it will set on the polyliine the entered amountOfMarkers with the preset interval.
   * If interval placing is disabled, it will evenly set markers along the whole polyline between start and finish sensors
   */
  getMilestones(
    start: { lat: number, lng: number },
    finish: { lat: number, lng: number },
    amountOfMarkers: number,
    intervalPlacingInterval: number,
  ) {
    return new Promise<{ result: LatLon[]; polyline: google.maps.Polyline }>((resolve, reject) => {
      let distance = 0;
      let distanceTwo = 0;
      const directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();

      const request: google.maps.DirectionsRequest = {
        origin: new google.maps.LatLng(start),
        destination: new google.maps.LatLng(finish),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      };
      const requestTwo: google.maps.DirectionsRequest = {
        origin: new google.maps.LatLng(finish),
        destination: new google.maps.LatLng(start),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      };

      directionsService.route(request, (response, status) => {
        directionsService.route(requestTwo, (responseTwo, statusTwo) => {
          if (status === 'OK' && statusTwo === 'OK') {
            let polyline: google.maps.Polyline = new google.maps.Polyline({
              path: [],
              strokeColor: '#02aed6',
            });
            const polylineTwo: google.maps.Polyline = new google.maps.Polyline({
              path: [],
              strokeColor: '#02aed6',
            });

            if (!this.optionsStore.snapToRoad) {
              polyline.setPath([request.origin as google.maps.LatLngLiteral, request.destination as google.maps.LatLngLiteral]);
            } else {
              polyline.setPath([]);
              polylineTwo.setPath([]);
            }

            const legs = response?.routes[0].legs || [];

            const legsTwo = responseTwo?.routes[0].legs || [];
            if (this.optionsStore.snapToRoad) {
              for (const leg of legs) {
                const steps = leg.steps;
                for (const step of steps) {
                  const nextSegment = step.path;

                  for (const segment of nextSegment) {
                    polyline.getPath().push(segment);
                  }
                }
              }

              for (const leg of legsTwo) {
                const steps = leg.steps;
                for (const step of steps) {
                  const nextSegment = step.path;

                  for (const segment of nextSegment) {
                    polylineTwo.getPath().push(segment);
                  }
                }
              }
            }

            for (let i = 1; i < polyline.getPath().getLength(); i++) {
              distance += google.maps.geometry.spherical
                .computeDistanceBetween(
                  polyline.getPath().getAt(i),
                  polyline.getPath().getAt(i - 1),
                );
            }

            if (this.optionsStore.snapToRoad) {
              for (let i = 1; i < polylineTwo.getPath().getLength(); i++) {
                distanceTwo += google.maps.geometry.spherical
                  .computeDistanceBetween(
                    polylineTwo.getPath().getAt(i),
                    polylineTwo.getPath().getAt(i - 1),
                  );
              }
              this.invertedRoute = (distance > distanceTwo);
              distance = (this.invertedRoute) ? distanceTwo : distance;
              polyline = (this.invertedRoute) ? polylineTwo : polyline;
            }

            this.oldPolyline = polyline;

            let interval: number;
            if (!this.optionsStore.intervalPlacing) {
              interval = distance / (amountOfMarkers - 1);
            } else {
              if (!intervalPlacingInterval || isNaN(parseFloat(intervalPlacingInterval as unknown as string))) {
                reject('Selected interval is not a number');
              }
              interval = intervalPlacingInterval;
            }

            let result = this.gMilestone(polyline, +interval);

            if (!this.optionsStore.intervalPlacing && result) {
              if (result.length > amountOfMarkers - 2) {
                result = result.slice(0, amountOfMarkers - 2);
              }
              if (this.invertedRoute) {
                result = result.reverse();
              }

              resolve({ result, polyline });
            } else if (this.optionsStore.intervalPlacing && result) {
              if (result.length < amountOfMarkers - 1) {
                reject('Exceeded the number of sensors on specified interval');
              }
              // if (this.invertedRoute) result = result.reverse()
              result = result.slice(0, amountOfMarkers - 1);
              resolve({ result, polyline });
            }
          } else {
            reject('Failed to setup polyline');
          }
        });
      });
    });
  }

  /**
   * @param polyline google.maps.polyline object that has been made of the route segments
   * @param meters interval for points in meters
   * @description returns the list of milestones on the polyline
   */
  gMilestone(polyline: google.maps.Polyline, meters: number): LatLon[] {
    let next = meters;
    const points: google.maps.Marker[] = [];
    let dist = 0;
    let olddist = 0;

    if (this.invertedRoute && this.optionsStore.intervalPlacing) {
      for (let i = polyline.getPath().getLength() - 1; (i > 0); i--) {
        olddist = dist;
        dist += google.maps.geometry.spherical.computeDistanceBetween(polyline.getPath().getAt(i), polyline.getPath().getAt(i - 1));
        while (dist > next) {
          const p1 = polyline.getPath().getAt(i);
          const p2 = polyline.getPath().getAt(i - 1);
          const m = (next - olddist) / (dist - olddist);
          points.push(new google.maps.Marker(
            {
              position: new google.maps.LatLng(
                p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m),
            },
          ));
          next += meters;
        }
      }
    } else {
      for (let i = 1; (i < polyline.getPath().getLength()); i++) {
        olddist = dist;
        dist += google.maps.geometry.spherical.computeDistanceBetween(polyline.getPath().getAt(i), polyline.getPath().getAt(i - 1));
        while (dist > next) {
          const p1 = polyline.getPath().getAt(i - 1);
          const p2 = polyline.getPath().getAt(i);
          const m = (next - olddist) / (dist - olddist);
          points.push(new google.maps.Marker(
            {
              position: new google.maps.LatLng(
                p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m),
            },
          ));
          next += meters;
        }
      }
    }

    return points.map(point => {
      return {
        lat: point.getPosition()?.lat() || 0,
        lon: point.getPosition()?.lng() || 0,
      };
    });
  }

  /**
   * @param coords an object of type {lat: number, lon: number}
   * @description returns the nearest point on the road
   */
  setToRoad(coords: LatLon): Promise<LatLon> {
    return new Promise((resolve, reject) => {
      if (!this.optionsStore.snapToRoad) {
        const res = {
          lat: coords.lat,
          lon: coords.lon,
        };
        resolve(res);
      } else if (this.optionsStore.snapToRoad) {
        const directionsService = new google.maps.DirectionsService();

        const request = {
          origin: new google.maps.LatLng(coords.lat, coords.lon),
          destination: new google.maps.LatLng(coords.lat, coords.lon),
          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true,
        };

        directionsService.route(request, (response, status) => {
          if (status === 'OK') {
            const path = response?.routes[0].overview_path || [];
            const point = new google.maps.Marker({
              position: path[0],
              draggable: true,
            });
            const res = {
              lat: point.getPosition()?.lat() || 0,
              lon: point.getPosition()?.lng() || 0,
            };
            resolve(res);
          } else {
            reject(status);
          }
        });
      }
    });
  }
}
