/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DeviceColdData
 */
export interface DeviceColdData {
    /**
     * `Device Primary Key`. HEX string
     * @type {string}
     * @memberof DeviceColdData
     */
    readonly device_id: string;
    /**
     * @type {string}
     * @memberof DeviceColdData
     */
    maintenance_id?: string;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    last_fw_updatetime?: Date;
    /**
     * @type {string}
     * @memberof DeviceColdData
     */
    production_zone_number?: string;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    assembling_datetime?: Date;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    purchase_datetime?: Date;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    expiration_datetime?: Date;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    disabling_datetime?: Date;
    /**
     * @type {Date}
     * @memberof DeviceColdData
     */
    last_update_datetime?: Date;
    /**
     * @type {string}
     * @memberof DeviceColdData
     */
    disabling_reason?: string;
}
