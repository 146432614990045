/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface DeviceUpdateFields
 */
export interface DeviceUpdateFields {
    /**
     * Hardware type of device
     * @type {string}
     * @memberof DeviceUpdateFields
     */
    hardware_type: string | null;
    /**
     * @type {boolean}
     * @memberof DeviceUpdateFields
     */
    activation_status: boolean;
    /**
     * @type {boolean}
     * @memberof DeviceUpdateFields
     */
    damaged_status: boolean;
    /**
     * `Only Admin can use this field` *Readonly for another users*
     * @type {number}
     * @memberof DeviceUpdateFields
     */
    owner_id: number;
}
