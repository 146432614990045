/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * Device Management
 * Device Manager API CRUD https://bitbucket.org/nwaveio/device-management-openapi/src/master/
 *
 * The version of the OpenAPI document: 2.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EncryptionKeys,
    SoftType,
} from './';

/**
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * `Device Primary Key`. HEX string
     * @type {string}
     * @memberof Device
     */
    readonly device_id: string;
    /**
     * Hardware type of device
     * @type {string}
     * @memberof Device
     */
    hardware_type?: string | null;
    /**
     * `Position Primary Key`
     * @type {number}
     * @memberof Device
     */
    readonly position_id?: number;
    /**
     * @type {string}
     * @memberof Device
     */
    firmware_hash?: string;
    /**
     * @type {EncryptionKeys}
     * @memberof Device
     */
    encryption_keys?: EncryptionKeys;
    /**
     * @type {boolean}
     * @memberof Device
     */
    activation_status: boolean;
    /**
     * @type {boolean}
     * @memberof Device
     */
    damaged_status: boolean;
    /**
     * `Only Admin can use this field` *Readonly for another users*
     * @type {number}
     * @memberof Device
     */
    owner_id: number;
    /**
     * @type {string}
     * @memberof Device
     */
    high_level_protocol_version?: string;
    /**
     * @type {SoftType}
     * @memberof Device
     */
    soft_type?: SoftType;
    /**
     * Device calibration status
     * @type {boolean}
     * @memberof Device
     */
    calibration_status: boolean;
    /**
     * Device calibration datetime
     * @type {Date}
     * @memberof Device
     */
    calibration_date_time?: Date | null;
}
