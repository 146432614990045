/* eslint no-unused-vars: off, @typescript-eslint/no-unused-vars: off */
/**
 * User Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ProjectIdsAll,
} from './';

/**
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * @type {string}
     * @memberof ApiKey
     */
    id: string;
    /**
     * @type {string}
     * @memberof ApiKey
     */
    key: string;
    /**
     * @type {string}
     * @memberof ApiKey
     */
    name: string;
    /**
     * owner is auto detected from the permssion owner
     * @type {number}
     * @memberof ApiKey
     */
    owner_id: number;
    /**
     * @type {ProjectIdsAll | Array}
     * @memberof ApiKey
     */
    project_ids: ProjectIdsAll | Array<number>;
    /**
     * @type {number}
     * @memberof ApiKey
     */
    permission_id?: number;
    /**
     * @type {Date}
     * @memberof ApiKey
     */
    creation_time: Date;
}
